import React from "react";
import { motion } from "framer-motion";
import { Button } from "../components/ui/button";
import {
    BookCheck,
    PenLine,
    FileText,
    Users,
    ArrowRight,
    ChevronDown,
    ChevronUp,
} from "lucide-react";
import { useTranslation } from "../context/TranslationContext";
import { Link } from "react-router-dom";
import { Seo } from "../components/ui/seo";

export function LegalPage() {
    const [openFaq, setOpenFaq] = React.useState<number | null>(null);
    const { t } = useTranslation();

    // Safely handle array translations with type checking
    const legalServices = Array.isArray(
        t("legal.services", { returnObjects: true })
    )
        ? (t("legal.services", { returnObjects: true }) as any[])
        : [];

    const faqs = Array.isArray(
        t("legal.faq.questions", { returnObjects: true })
    )
        ? (t("legal.faq.questions", { returnObjects: true }) as any[])
        : [];

    const icons = [
        <BookCheck className="h-6 w-6 text-[#690f39]" />,
        <PenLine className="h-6 w-6 text-[#690f39]" />,
        <FileText className="h-6 w-6 text-[#690f39]" />,
        <Users className="h-6 w-6 text-[#690f39]" />,
    ];

    const handleFaqClick = (index: number) => {
        setOpenFaq(openFaq === index ? null : index);
    };

    const websiteSchema = {
        "@context": "https://schema.org",
        "@type": "WebPage",
        "name": "Legal Services | Lex Connect",
        "url": "https://www.lexconnect.ch/legal"
    };

    return (
        <>
            <Seo 
                title="Legal Services | Business Law Switzerland | Lex Connect"
                description="Professional legal services for businesses in Switzerland. Contract review, business setup, compliance, and expert legal consulting."
                path="/legal"
                schema={websiteSchema}
            />
            <div className="bg-gray-50">
                {/* Hero Section */}
                <section className="py-20">
                    <div className="mx-auto max-w-7xl px-6 lg:px-8">
                        <motion.div
                            initial={{ opacity: 0, y: 20 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.8 }}
                            className="text-center"
                        >
                            <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">
                                {t("legal.hero.title")}
                            </h1>
                            <p className="mt-6 text-lg leading-8 text-gray-600 max-w-2xl mx-auto">
                                {t("legal.hero.description")}
                            </p>
                        </motion.div>
                    </div>
                </section>

                {/* Services Grid */}
                <section className="py-10">
                    <div className="mx-auto max-w-3xl px-6 lg:px-8">
                        {legalServices.map((service: any, index: number) => (
                            <motion.div
                                key={index}
                                initial={{ opacity: 0, y: 20 }}
                                animate={{ opacity: 1, y: 0 }}
                                transition={{ duration: 0.5, delay: index * 0.1 }}
                                className="mb-8 last:mb-0"
                            >
                                <div className="relative overflow-hidden group">
                                    {/* Main Content */}
                                    <div className="bg-white rounded-2xl shadow-sm border border-gray-100 p-10">
                                        <div className="relative">
                                            <div className="flex items-center gap-5">
                                                <div className="p-4 rounded-xl bg-[#fff1f1] transform group-hover:-rotate-6 transition-transform duration-300">
                                                    {icons[index]}
                                                </div>
                                                <h3 className="text-2xl font-semibold text-gray-900">
                                                    {service.title}
                                                </h3>
                                            </div>
                                        </div>
                                    </div>

                                    {/* Features */}
                                    <div className="absolute inset-y-0 left-full w-full bg-gray-50 p-8 transform transition-transform duration-300 translate-x-0 group-hover:-translate-x-full">
                                        <div className="space-y-6">
                                            {Array.isArray(service.features) &&
                                                service.features.map(
                                                    (
                                                        feature: string,
                                                        idx: number
                                                    ) => (
                                                        <div
                                                            key={idx}
                                                            className="flex items-start gap-4"
                                                        >
                                                            <div className="mt-1 h-6 w-6 flex-shrink-0 rounded-md bg-[#fff1f1] flex items-center justify-center">
                                                                <ArrowRight className="h-3 w-3 text-[#690f39]" />
                                                            </div>
                                                            <p className="text-gray-600 text-lg leading-relaxed">
                                                                {feature}
                                                            </p>
                                                        </div>
                                                    )
                                                )}
                                        </div>
                                    </div>
                                </div>
                            </motion.div>
                        ))}
                    </div>
                </section>

                {/* FAQ Section */}
                <section className="py-24 bg-white">
                    <div className="mx-auto max-w-3xl px-6 lg:px-8">
                        <motion.div
                            initial={{ opacity: 0, y: 20 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.8 }}
                            className="text-center mb-16"
                        >
                            <h2 className="text-3xl font-bold tracking-tight text-gray-900">
                                {t("legal.faq.title")}
                            </h2>
                        </motion.div>

                        <div className="space-y-4">
                            {faqs.map((faq: any, index: number) => (
                                <motion.div
                                    key={index}
                                    initial={{ opacity: 0, y: 20 }}
                                    animate={{ opacity: 1, y: 0 }}
                                    transition={{
                                        duration: 0.5,
                                        delay: index * 0.1,
                                    }}
                                    className="border border-gray-200 rounded-lg overflow-hidden"
                                >
                                    <button
                                        onClick={() => handleFaqClick(index)}
                                        className="w-full px-6 py-4 flex items-center justify-between bg-white hover:bg-gray-50 transition-colors"
                                    >
                                        <span className="font-medium text-left text-gray-900">
                                            {faq.question}
                                        </span>
                                        {openFaq === index ? (
                                            <ChevronUp className="h-5 w-5 text-gray-500" />
                                        ) : (
                                            <ChevronDown className="h-5 w-5 text-gray-500" />
                                        )}
                                    </button>
                                    {openFaq === index && (
                                        <div className="px-6 py-4 bg-gray-50">
                                            <p className="text-gray-600">
                                                {faq.answer}
                                            </p>
                                        </div>
                                    )}
                                </motion.div>
                            ))}
                        </div>
                    </div>
                </section>

                {/* CTA Section */}
                <section className="py-16 bg-gray-50">
                    <div className="mx-auto max-w-7xl px-6 lg:px-8 text-center">
                        <h2 className="text-3xl font-bold tracking-tight text-gray-900 mb-8">
                            {t("legal.cta.title")}
                        </h2>
                        <Link to="/contact">
                            <Button
                                size="lg"
                                className="bg-[#690f39] hover:bg-[#8b1d4c] text-white"
                            >
                                {t("legal.cta.button")}
                            </Button>
                        </Link>
                    </div>
                </section>
            </div>
        </>
    );
}
