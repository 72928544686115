export const stripeService = {
  async createCheckoutSession(templateId: string, locale: string, price: number): Promise<string> {
    try {

      const url = `${process.env.REACT_APP_API_URL}payments/create-checkout-session`;


      // Convert price to cents for backend (e.g., 110 -> 11000)
      const priceInCents = Math.round(price * 100);

      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          templateId,
          locale,
          price: priceInCents // Send price in cents to match backend expectation
        }),
      })

      if (!response.ok) {
        const error = await response.json()
        throw new Error(error.message || 'Failed to create checkout session')
      }

      const { url: checkoutUrl } = await response.json()
      return checkoutUrl
    } catch (error) {
      console.error('Checkout error:', error)
      throw error
    }
  }
}
