import React, { useState } from 'react'
import { motion } from 'framer-motion'
import { Button } from '../components/ui/button'
import { Input } from '../components/ui/input'
import { Textarea } from '../components/ui/textarea'
import {
  MapPin,
  Mail,
  ArrowRight,
  CheckCircle,
  Send,
  Loader2
} from 'lucide-react'
import { contactService } from '../services/contactService'
import { useToast } from '../components/ui/toast'
import { useTranslation } from '../context/TranslationContext'
import { Checkbox } from '../components/ui/checkbox'

export function ContactPage() {
  // Initialize hooks for toast notifications and translations
  const { toast } = useToast()
  const { t, language } = useTranslation()
  const [isSubmitting, setIsSubmitting] = useState(false)

  // Add email decoding function
  const decodeEmail = (encodedEmail: string) => {
    return encodedEmail.replace(/\[at\]/g, '@').replace(/\[dot\]/g, '.');
  }

  function renderEmail() {
    const encodedEmail = String(t('contactp.info.email.value'));
    return (
      <span className="select-all">{decodeEmail(encodedEmail)}</span>
    );
  }

  // Form state management with TypeScript interface
  const [formState, setFormState] = useState({
    firstName: '',
    lastName: '',
    email: '',
    services: {
      legal: false,
      language: false,
      other: false
    },
    message: '',
    privacyAccepted: false
  })

  // Email validation helper function
  const validateEmail = (email: string) => {
    return email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/)
  }

  // Form submission handler with validation and API call
  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault()
    setIsSubmitting(true)

    // Validate required fields
    if (!formState.firstName || !formState.lastName || !formState.email || !formState.message) {
      toast({
        title: String(t('contactp.validation.required.title')),
        description: String(t('contactp.validation.required.description')),
        variant: "destructive",
      })
      setIsSubmitting(false)
      return
    }

    // Validate email format
    if (!validateEmail(formState.email)) {
      toast({
        title: String(t('contactp.validation.email.title')),
        description: String(t('contactp.validation.email.description')),
        variant: "destructive",
      })
      setIsSubmitting(false)
      return
    }

    // Validate service selection
    if (!formState.services.legal && !formState.services.language && !formState.services.other) {
      toast({
        title: String(t('contactp.validation.services.title')),
        description: String(t('contactp.validation.services.description')),
        variant: "destructive",
      })
      setIsSubmitting(false)
      return
    }

    // Validate privacy policy acceptance
    if (!formState.privacyAccepted) {
      toast({
        title: String(t('contactp.validation.privacy.title')),
        description: String(t('contactp.validation.privacy.description')),
        variant: "destructive",
      })
      setIsSubmitting(false)
      return
    }

    // Submit form data and handle response
    try {
      await contactService.submitForm({
        ...formState,
        language
      })
      toast({
        title: String(t('contactp.toast.success.title')),
        description: String(t('contactp.toast.success.description')),
      })
      // Reset form after successful submission
      setFormState({
        firstName: '',
        lastName: '',
        email: '',
        services: { legal: false, language: false, other: false },
        message: '',
        privacyAccepted: false
      })
    } catch (error) {
      toast({
        title: String(t('contactp.toast.error.title')),
        description: String(t('contactp.toast.error.description')),
        variant: "destructive",
      })
    } finally {
      setIsSubmitting(false)
    }
  }

  // Safely handle array translations for expectations section
  const expectations = Array.isArray(t('contactp.expectations.items', { returnObjects: true }))
    ? (t('contactp.expectations.items', { returnObjects: true }) as string[])
    : []

  return (
    <div className="bg-gray-50">
      {/* Hero Section: Title and Description */}
      <section className="py-20">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
            className="text-center"
          >
            <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">
              {t('contactp.hero.title')}
            </h1>
          </motion.div>
        </div>
      </section>

      {/* Main Content Section: Form and Info */}
      <section className="py-10 -mt-10">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
            {/* Left Column: Contact Form */}
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8 }}
              className="lg:col-span-2"
            >
              <div className="bg-white rounded-2xl shadow-xl p-8">
                {/* Form Header */}
                <div className="flex items-center gap-4 mb-8">
                  <div className="p-3 rounded-xl bg-[#fff1f1]">
                    <Send className="h-6 w-6 text-[#690f39]" />
                  </div>
                  <div>
                    <h2 className="text-2xl font-bold text-gray-900">
                      {t('contactp.form.title')}
                    </h2>
                    <p className="text-gray-600">
                      {t('contactp.form.subtitle')}
                    </p>
                  </div>
                </div>

                {/* Contact Form */}
                <form onSubmit={handleSubmit} className="space-y-6">
                  {/* Name Fields */}
                  <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
                    <div>
                      <label className="text-sm font-medium text-gray-900">
                        {t('contactp.form.fields.firstName')}
                      </label>
                      <Input
                        required
                        value={formState.firstName}
                        onChange={(e) => setFormState(prev => ({
                          ...prev,
                          firstName: e.target.value
                        }))}
                        placeholder={t('contact.form.placeholders.firstName') as string}
                        className="mt-1 bg-gray-50 border-gray-200"
                      />
                    </div>
                    <div>
                      <label className="text-sm font-medium text-gray-900">
                        {t('contactp.form.fields.lastName')}
                      </label>
                      <Input
                        required
                        value={formState.lastName}
                        onChange={(e) => setFormState(prev => ({
                          ...prev,
                          lastName: e.target.value
                        }))}
                        placeholder={t('contact.form.placeholders.lastName') as string}
                        className="mt-1 bg-gray-50 border-gray-200"
                      />
                    </div>
                  </div>

                  {/* Email Field */}
                  <div>
                    <label className="text-sm font-medium text-gray-900">
                      {t('contactp.form.fields.email')}
                    </label>
                    <Input
                      required
                      type="email"
                      value={formState.email}
                      onChange={(e) => setFormState(prev => ({
                        ...prev,
                        email: e.target.value
                      }))}
                      placeholder={t('contact.form.placeholders.email') as string}
                      className="mt-1 bg-gray-50 border-gray-200"
                    />
                  </div>

                  {/* Services Checkboxes */}
                  <div>
                    <label className="text-sm font-medium text-gray-900">
                      {t('contactp.form.fields.services.title')}
                    </label>
                    <div className="flex flex-col sm:flex-row gap-2 sm:gap-6 mt-2">
                      <label className="flex items-center space-x-2">
                        <Checkbox
                          id="legal-service"
                          checked={formState.services.legal}
                          onChange={(e) => setFormState(prev => ({
                            ...prev,
                            services: {
                              ...prev.services,
                              legal: e.target.checked
                            }
                          }))}
                        />
                        <span className="text-gray-700 text-sm">{t('contact.form.services.legal')}</span>
                      </label>
                      <label className="flex items-center space-x-2">
                        <Checkbox
                          id="language-service"
                          checked={formState.services.language}
                          onChange={(e) => setFormState(prev => ({
                            ...prev,
                            services: {
                              ...prev.services,
                              language: e.target.checked
                            }
                          }))}
                        />
                        <span className="text-gray-700 text-sm">{t('contact.form.services.language')}</span>
                      </label>
                      <label className="flex items-center space-x-2">
                        <Checkbox
                          id="other-service"
                          checked={formState.services.other}
                          onChange={(e) => setFormState(prev => ({
                            ...prev,
                            services: {
                              ...prev.services,
                              other: e.target.checked
                            }
                          }))}
                        />
                        <span className="text-gray-700 text-sm">{t('contact.form.services.other')}</span>
                      </label>
                    </div>
                  </div>

                  {/* Message Field */}
                  <div>
                    <label className="text-sm font-medium text-gray-900">
                      {t('contactp.form.fields.message')}
                    </label>
                    <Textarea
                      required
                      value={formState.message}
                      onChange={(e) => setFormState(prev => ({
                        ...prev,
                        message: e.target.value
                      }))}
                      placeholder={t('contact.form.placeholders.message') as string}
                      rows={4}
                      className="mt-1 bg-gray-50 border-gray-200"
                    />
                  </div>

                  {/* Privacy Policy Checkbox */}
                  <div className="flex items-center space-x-2">
                    <Checkbox
                      id="privacy-policy"
                      checked={formState.privacyAccepted}
                      onChange={(e) => setFormState(prev => ({
                        ...prev,
                        privacyAccepted: e.target.checked
                      }))}
                    />
                    <label htmlFor="privacy-policy" className="text-sm text-gray-600">
                      {t('contactp.form.privacyAccept.before')}{' '}
                      <a href="/privacy" className="text-[#690f39] hover:underline">
                        {t('contactp.form.privacyAccept.link')}
                      </a>
                      {t('contactp.form.privacyAccept.after')}
                    </label>
                  </div>

                  {/* Submit Button */}
                  <Button
                    type="submit"
                    className="w-full bg-[#690f39] hover:bg-[#8b1d4c] text-white"
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? (
                      <>
                        <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                        {t('contactp.form.submitting')}
                      </>
                    ) : (
                      <>
                        {t('contactp.form.button')}
                        <ArrowRight className="ml-2 h-4 w-4" />
                      </>
                    )}
                  </Button>
                </form>
              </div>
            </motion.div>

            {/* Right Column: Contact Info & Expectations */}
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8 }}
              className="space-y-8"
            >
              {/* Contact Information Card */}
              <div className="bg-white rounded-2xl shadow-xl p-8">
                <h3 className="text-xl font-semibold text-gray-900 mb-6">
                  {t('contactp.info.title')}
                </h3>
                <div className="space-y-6">
                  {/* Location Info */}
                  <div className="flex items-start gap-4">
                    <MapPin className="h-6 w-6 text-[#690f39] mt-1" />
                    <div>
                      <p className="font-medium text-gray-900">{t('contactp.info.location.title')}</p>
                      <p className="text-gray-600">{t('contactp.info.location.value')}</p>
                    </div>
                  </div>
                  {/* Email Info */}
                  <div className="flex items-start gap-4">
                    <Mail className="h-6 w-6 text-[#690f39] mt-1" />
                    <div>
                      <h3 className="font-semibold">{t('contact.info.email.title')}</h3>
                      <p className="text-gray-600">
                        {renderEmail()}
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              {/* What to Expect Card */}
              <div className="bg-white rounded-2xl shadow-xl p-8">
                <h3 className="text-xl font-semibold text-gray-900 mb-6">
                  {t('contactp.expectations.title')}
                </h3>
                <div className="space-y-4">
                  {expectations.map((item, index) => (
                    <div key={index} className="flex items-center gap-3">
                      <CheckCircle className="h-5 w-5 text-[#690f39]" />
                      <span className="text-gray-600">{item}</span>
                    </div>
                  ))}
                </div>
              </div>
            </motion.div>
          </div>
        </div>
      </section>
    </div>
  )
}
