import React, { createContext, useContext, useState } from 'react'
import { CheckCircle2, XCircle } from 'lucide-react'

interface Toast {
  id: number
  title: string
  description?: string
  variant?: 'default' | 'destructive'
}

interface ToastContextType {
  toast: (props: Omit<Toast, 'id'>) => void
}

const ToastContext = createContext<ToastContextType | undefined>(undefined)

export function ToastProvider({ children }: { children: React.ReactNode }) {
  const [toasts, setToasts] = useState<Toast[]>([])

  const addToast = (props: Omit<Toast, 'id'>) => {
    const id = Date.now()
    setToasts(prev => [...prev, { ...props, id }])
    setTimeout(() => {
      setToasts(prev => prev.filter(t => t.id !== id))
    }, 3000)
  }

  return (
    <ToastContext.Provider value={{ toast: addToast }}>
      {children}
      <div className="fixed inset-0 flex items-center justify-center pointer-events-none z-50">
        <div className="flex flex-col gap-3 max-w-xl w-full mx-4">
          {toasts.map(toast => (
            <div
              key={toast.id}
              className={`
                rounded-lg shadow-xl pointer-events-auto w-full
                animate-in fade-in slide-in-from-top-1 duration-300
                ${toast.variant === 'destructive' ? 'bg-white' : 'bg-white'}
              `}
            >
              <div
                className={`
                  p-3 rounded-t-lg flex items-center gap-3
                  ${toast.variant === 'destructive'
                    ? 'bg-red-600'
                    : 'bg-gradient-to-r from-[#690f39] to-[#8b1d4c]'
                  }
                `}
              >
                {toast.variant === 'destructive'
                  ? <XCircle className="h-5 w-5 text-white flex-shrink-0" />
                  : <CheckCircle2 className="h-5 w-5 text-white flex-shrink-0" />
                }
                <h3 className="font-semibold text-base text-white">{toast.title}</h3>
              </div>
              {toast.description && (
                <div className="p-3">
                  <p className="text-gray-700 text-sm">{toast.description}</p>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </ToastContext.Provider>
  )
}

export function useToast() {
  const context = useContext(ToastContext)
  if (!context) throw new Error('useToast must be used within ToastProvider')
  return context
}
