import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { Button } from "./ui/button";
import { Menu, X } from "lucide-react";
import { motion, AnimatePresence } from "framer-motion";
import { LanguageSwitcher } from "./LanguageSwitcher";
import { useTranslation } from "../context/TranslationContext";
import MailerLiteForm, { showMailerLitePopup } from "./MailerLiteForm"; // Adjust the import path as needed

/**
 * Main header component with responsive navigation
 * Features:
 * - Mobile-friendly hamburger menu
 * - Language switcher
 * - Animated navigation items
 * - Gradient background
 * - Active route highlighting
 */
export function Header() {
    const location = useLocation();
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const { t } = useTranslation();

    /**
     * Determines if a navigation link is active based on current route
     * @param path - Route path to check
     * @returns CSS classes for active/inactive state
     */
    const isActive = (path: string) => {
        return location.pathname === path
            ? "text-white"
            : "text-[#ffd4d4] hover:text-white";
    };

    const handleMenuToggle = () => setIsMenuOpen(!isMenuOpen);
    const handleMenuClose = () => setIsMenuOpen(false);

    return (
        <header className="w-full">
            {/* Main Navigation Bar */}
            <nav className="bg-gradient-to-r from-[#690f39] from-50% via-[#8b1d4c] to-[#a62657] relative">
                {/* Language Switcher - Fixed Position */}
                <div className="absolute top-4 right-6 z-30">
                    <LanguageSwitcher />
                </div>

                <div className="mx-auto max-w-7xl px-6 py-6 relative z-20 flex items-center min-h-[120px]">
                    <div className="flex items-center justify-between w-full">
                        {/* Logo/Brand */}
                        <Link
                            to="/"
                            className="text-3xl xl:text-4xl tracking-tighter font-bold text-white flex items-center whitespace-nowrap"
                        >
                            Lex Connect.<span className="animate-pulse">.</span>
                        </Link>

                        {/* Desktop Navigation Links */}
                        <div className="hidden lg:flex items-center space-x-6 tracking-tight px-2">
                            <Link
                                to="/"
                                className={`transition-colors text-md ${isActive(
                                    "/"
                                )}`}
                            >
                                {t("navigation.home")}
                            </Link>
                            <Link
                                to="/about"
                                className={`transition-colors text-md ${isActive(
                                    "/about"
                                )}`}
                            >
                                {t("navigation.about")}
                            </Link>
                            <Link
                                to="/legal"
                                className={`transition-colors text-md ${isActive(
                                    "/legal"
                                )}`}
                            >
                                {t("navigation.legal")}
                            </Link>
                            <Link
                                to="/templates"
                                className={`transition-colors text-md ${isActive(
                                    "/templates"
                                )}`}
                            >
                                {t("navigation.templates")}
                            </Link>
                            <Link
                                to="/bonus"
                                className={`transition-colors text-md ${isActive(
                                    "/bonus"
                                )}`}
                            >
                                {t("navigation.bonus")}
                            </Link>
                            <Link
                                to="/contact"
                                className={`transition-colors text-md ${isActive(
                                    "/contact"
                                )}`}
                            >
                                {t("navigation.contact")}
                            </Link>
                        </div>

                        {/* Desktop CTA Section */}
                        <div className="hidden lg:flex items-center space-x-4">
                            {/* Desktop CTA Button */}
                            <Link to="/schedule" className="block">
                                <Button
                                    size="lg"
                                    className="bg-white rounded-full hover:bg-[#ffd4d4] text-[#690f39]"
                                >
                                    {t("navigation.schedule")}
                                </Button>
                            </Link>
                            {/* Existing Subscribe Button */}
              <Button
                size="lg"
                className="bg-white rounded-full hover:bg-[#ffd4d4] text-[#690f39]"
                onClick={showMailerLitePopup}
              >
                Newsletter
              </Button>
              {/* Include MailerLiteForm to load the script */}
              <MailerLiteForm />
                        </div>

                        {/* Mobile Menu Toggle */}
                        <button
                            onClick={handleMenuToggle}
                            className="lg:hidden text-white p-2"
                        >
                            {isMenuOpen ? <X size={24} /> : <Menu size={24} />}
                        </button>
                    </div>
                </div>

                {/* Mobile Navigation Menu with Animations */}
                <AnimatePresence>
                    {isMenuOpen && (
                        <motion.div
                            initial={{ opacity: 0, height: 0 }}
                            animate={{ opacity: 1, height: "auto" }}
                            exit={{ opacity: 0, height: 0 }}
                            transition={{ duration: 0.2 }}
                            className="lg:hidden bg-gradient-to-r from-[#690f39] from-50% via-[#8b1d4c] to-[#a62657] relative z-20"
                        >
                            <div className="px-6 py-4">
                                {/* Mobile Navigation Grid */}
                                <div className="grid grid-cols-2 gap-2 mb-4">
                                    {/* Mobile Navigation Links */}
                                    <Link
                                        to="/"
                                        className={`text-center py-2 ${isActive(
                                            "/"
                                        )} hover:bg-white/10 rounded transition-colors`}
                                        onClick={handleMenuClose}
                                    >
                                        {t("navigation.home")}
                                    </Link>
                                    <Link
                                        to="/about"
                                        className={`text-center py-2 ${isActive(
                                            "/about"
                                        )} hover:bg-white/10 rounded transition-colors`}
                                        onClick={handleMenuClose}
                                    >
                                        {t("navigation.about")}
                                    </Link>
                                    <Link
                                        to="/legal"
                                        className={`text-center py-2 ${isActive(
                                            "/legal"
                                        )} hover:bg-white/10 rounded transition-colors`}
                                        onClick={handleMenuClose}
                                    >
                                        {t("navigation.legal")}
                                    </Link>
                                    <Link
                                        to="/language"
                                        className={`text-center py-2 ${isActive(
                                            "/language"
                                        )} hover:bg-white/10 rounded transition-colors`}
                                        onClick={handleMenuClose}
                                    >
                                        {t("navigation.language")}
                                    </Link>
                                    <Link
                                        to="/templates"
                                        className={`text-center py-2 ${isActive(
                                            "/templates"
                                        )} hover:bg-white/10 rounded transition-colors`}
                                        onClick={handleMenuClose}
                                    >
                                        {t("navigation.templates")}
                                    </Link>
                                    <Link
                                        to="/bonus"
                                        className={`text-center py-2 ${isActive(
                                            "/bonus"
                                        )} hover:bg-white/10 rounded transition-colors`}
                                        onClick={handleMenuClose}
                                    >
                                        {t("navigation.bonus")}
                                    </Link>
                                    <Link
                                        to="/contact"
                                        className={`text-center py-2 ${isActive(
                                            "/contact"
                                        )} hover:bg-white/10 rounded transition-colors`}
                                        onClick={handleMenuClose}
                                    >
                                        {t("navigation.contact")}
                                    </Link>
                                </div>
                                {/* Mobile CTA Button */}
                                <div className="flex justify-center space-x-4">
                                    <Link to="/schedule" className="block">
                                        <Button
                                            size="lg"
                                            className="bg-white hover:bg-[#ffd4d4] text-[#690f39]"
                                            onClick={handleMenuClose}
                                        >
                                            {t("navigation.schedule")}
                                        </Button>
                                    </Link>
                                    <Button
                                        size="lg"
                                        className="bg-white hover:bg-[#ffd4d4] text-[#690f39]"
                                        onClick={() => {
                                            handleMenuClose();
                                            showMailerLitePopup();
                                        }}
                                    >
                                        Newsletter
                                    </Button>
                                </div>
                            </div>
                        </motion.div>
                    )}
                </AnimatePresence>

                {/* Decorative Wave SVG */}
                <div className="absolute -bottom-1 left-0 right-0 z-10">
                    <div className="bg-gradient-to-r from-[#690f39] from-50% via-[#8b1d4c] to-[#a62657]">
                        <svg
                            className="w-full h-8"
                            viewBox="0 0 1440 48"
                            preserveAspectRatio="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M0 48h1440V0c-151.32 35.347-534.2 28.42-759.4 23.48-231.78-5.09-377.92 7.52-680.6 19.52V48z"
                                className="fill-gray-50"
                            />
                        </svg>
                    </div>
                </div>
            </nav>
        </header>
    );
}
