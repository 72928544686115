import React from 'react'
import { motion } from 'framer-motion'
import { useTranslation } from '../context/TranslationContext'

export function PrivacyPage() {
  const { t, language } = useTranslation()

  // Add email decoding function
  const decodeEmail = (encodedEmail: string) => {
    return encodedEmail.replace(/\[at\]/g, '@').replace(/\[dot\]/g, '.');
  }

  function renderWithLinks(text: string) {
    const linkPhrases = {
      fr: {
        calendly: 'politique de confidentialité ici',
        stripe: 'politique de confidentialité ici',
        linkedin: 'Politique de confidentialité',
        instagram: 'Politique de confidentialité'
      },
      en: {
        calendly: 'privacy policy here',
        stripe: 'privacy policy here',
        linkedin: 'Privacy Policy',
        instagram: 'Privacy Policy'
      }
    }

    // Get the appropriate link based on the platform mentioned in the text
    function getLink(text: string) {
      const cleanText = text.toLowerCase()
      if (cleanText.includes('calendly')) return 'https://calendly.com/privacy'
      if (cleanText.includes('stripe')) return 'https://stripe.com/privacy'
      if (cleanText.includes('linkedin')) return 'https://www.linkedin.com/legal/privacy-policy'
      if (cleanText.includes('instagram')) return 'https://privacycenter.instagram.com/'
      return ''
    }

    const phrases = linkPhrases[language]
    const href = getLink(text)
    if (!href) return text

    // Determine which phrase to use based on the platform
    let phrase = ''
    const cleanText = text.toLowerCase()
    if (cleanText.includes('calendly')) {
      phrase = phrases.calendly
    } else if (cleanText.includes('stripe')) {
      phrase = phrases.stripe
    } else if (cleanText.includes('linkedin')) {
      phrase = phrases.linkedin
    } else if (cleanText.includes('instagram')) {
      phrase = phrases.instagram
    }

    if (!text.includes(phrase)) return text

    const parts = text.split(phrase)
    return (
      <>
        {parts[0]}
        <a
          href={href}
          className="text-[#9A1B3E] hover:underline"
          target="_blank"
          rel="noopener noreferrer"
        >
          {phrase}
        </a>
        {parts[1]}
      </>
    )
  }

  function renderContent(text: string) {
    // First check if it's an email that needs to be rendered
    if (text.includes('[email]')) {
      const emailRegex = /\[email\](.*?)\[\/email\]/;
      const match = text.match(emailRegex);
      if (match) {
        const encodedEmail = match[1];
        const parts = text.split(/\[email\].*?\[\/email\]/);
        return (
          <>
            {parts[0]}
            <span className="select-all">{decodeEmail(encodedEmail)}</span>
            {parts[1]}
          </>
        );
      }
    }

    // Handle bold text
    if (text.includes('*')) {
      const parts = text.split(/\*(.+?)\*/)
      return (
        <>
          {parts.map((part, index) => {
            // For bold sections (odd indices), check if they contain a platform name
            // If they do, we'll need to handle the link text that follows separately
            if (index % 2 === 1) {
              const platformPart = part.split(':')[0]
              const remainingPart = part.split(':').slice(1).join(':')
              return (
                <React.Fragment key={index}>
                  <strong>{platformPart}</strong>
                  {remainingPart && ':'}
                  {remainingPart && renderWithLinks(remainingPart)}
                </React.Fragment>
              )
            }
            // For non-bold sections, check for links
            return renderWithLinks(part)
          })}
        </>
      )
    }

    // If no bold text, just check for links
    return renderWithLinks(text)
  }

  return (
    <div className="bg-gray-50">
      {/* Hero Section */}
      <section className="pt-20 pb-12">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
            className="text-center"
          >
            <h1 className="text-4xl font-bold text-gray-900 tracking-tight sm:text-5xl">
              {t('privacy.hero.title')}
            </h1>
          </motion.div>
        </div>
      </section>

      {/* Main Content */}
      <section className="pb-24">
        <div className="mx-auto max-w-3xl px-6 lg:px-8">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            className="prose prose-lg max-w-none"
          >
            {/* Sections I to XIII */}
            {Array.from({ length: 13 }, (_, i) => i + 1).map((sectionNum) => (
              <div key={sectionNum} className="mb-12">
                <h2 className="text-2xl font-semibold text-gray-900 mt-12 mb-4">
                  {t(`privacy.sections.${sectionNum}.title`)}
                </h2>
                <div className="text-gray-600 text-justify">
                  {(() => {
                    const content = t(`privacy.sections.${sectionNum}.content`, { returnObjects: true })
                    const paragraphs = Array.isArray(content) ? content : [content]
                    return paragraphs.map((paragraph: string, index: number) => (
                      <p key={index} className="mb-4">
                        {renderContent(paragraph)}
                      </p>
                    ))
                  })()}
                  {/* Handle lists if present */}
                  {(() => {
                    const list = t(`privacy.sections.${sectionNum}.list`, { returnObjects: true })
                    if (Array.isArray(list)) {
                      return (
                        <ul className="list-disc pl-6 mb-4">
                          {list.map((item: string, index: number) => (
                            <li key={index} className="mb-2">
                              {renderContent(item)}
                            </li>
                          ))}
                        </ul>
                      )
                    }
                    return null
                  })()}
                  {/* Handle content2 if present */}
                  {(() => {
                    const content2 = t(`privacy.sections.${sectionNum}.content2`, { returnObjects: true })
                    if (Array.isArray(content2)) {
                      return content2.map((paragraph: string, index: number) => (
                        <p key={index} className="mb-4">
                          {renderContent(paragraph)}
                        </p>
                      ))
                    }
                    return null
                  })()}
                </div>
              </div>
            ))}

            {/* Last Updated */}
            <div className="text-gray-600 mt-12 pt-12 border-t border-gray-200 text-center">
              <p>{t('privacy.lastUpdated')}</p>
            </div>
          </motion.div>
        </div>
      </section>
    </div>
  )
}
