import React, { useState } from 'react'
import { motion } from 'framer-motion'
import { Button } from '../components/ui/button'
import { Card } from '../components/ui/card'
import { FileText, Send, Check } from 'lucide-react'
import { DownloadModal } from '../components/DownloadModal'
import { useTranslation } from '../context/TranslationContext'
import { Seo } from "../components/ui/seo";

// Define the structure for template data
interface Template {
  id: string;
  title: string;
  description: string;
  sideDescription: string;
  features: string[];
  price: {
    amount: string;
    note: string;
  };
}

export function BonusPage() {
  // State management for modal and selected template
  const [selectedTemplate, setSelectedTemplate] = useState<Template | null>(null)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const { t, language } = useTranslation()

  // Safely handle array translations with type checking
  const bonusTemplates = Array.isArray(t('bonus.templates', { returnObjects: true }))
    ? (t('bonus.templates', { returnObjects: true }) as unknown as Template[])
    : []

  // Handler for template download button clicks
  const handleDownloadClick = (template: Template) => {
    setSelectedTemplate(template)
    setIsModalOpen(true)
  }

  const websiteSchema = {
    "@context": "https://schema.org",
    "@type": "WebPage",
    "name": "Free Legal Resources | Lex Connect",
    "url": "https://www.lexconnect.ch/bonus"
  };

  return (
    <>
      <Seo 
        title="Free Legal Templates & Resources | Swiss Business Guide | Lex Connect"
        description="Download free legal document templates and guides for Swiss businesses. Access complimentary resources to help navigate Swiss business law."
        path="/bonus"
        schema={websiteSchema}
      />
      <div className="bg-gray-50">
        {/* Hero Section: Introduction and description */}
        <section className="py-20">
          <div className="mx-auto max-w-7xl px-6 lg:px-8">
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8 }}
              className="text-center"
            >
              <h1 className="text-4xl font-bold text-gray-900 tracking-tight sm:text-5xl">
                {t('bonus.hero.title')}
              </h1>
              <p className="mt-6 text-lg leading-8 text-gray-600 max-w-2xl mx-auto">
                {t('bonus.hero.description')}
              </p>
            </motion.div>
          </div>
        </section>

        {/* Templates Section: Display available templates */}
        <section className="py-4 pb-16">
          <div className="mx-auto max-w-7xl px-6 lg:px-8">
            {bonusTemplates.map((template, index) => (
              <motion.div
                key={template.id}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: index * 0.2 }}
                className="mb-16 last:mb-0"
              >
                {/* Template Card with Two-Column Layout */}
                <Card className="overflow-hidden bg-white">
                  <div className="grid grid-cols-1 lg:grid-cols-5 gap-0">
                    {/* Left Column: Template Overview */}
                    <div className="lg:col-span-2 p-8 bg-gradient-to-br from-[#690f39] to-[#8b1d4c] text-white">
                      <div className="sticky top-8">
                        {/* Template Header */}
                        <div className="flex items-center gap-4 mb-6">
                          <div className="p-2 rounded-lg bg-white/10">
                            <FileText className="h-6 w-6 text-white" />
                          </div>
                          <h3 className="text-2xl font-semibold">{template.title}</h3>
                        </div>

                        {/* Price and Description */}
                        <div className="mb-6">
                          <p className="text-3xl font-bold mb-2 flex items-center gap-2">
                            {template.price.amount} <span className="text-white/60 text-base">{template.price.note}</span>
                          </p>
                          <p className="text-white/80">{template.description}</p>
                        </div>

                        {/* Download Button */}
                        <Button
                          onClick={() => handleDownloadClick(template)}
                          className="w-full bg-white hover:bg-gray-100 text-[#690f39]"
                        >
                          {t('bonus.buttons.download')}
                          <Send className="ml-2 h-4 w-4" />
                        </Button>

                        {/* Language Availability Notice */}
                        {language === 'en' && (
                          <div className="mt-4 p-4 bg-[#fff1f1] rounded-lg">
                            <p className="text-[#690f39] text-sm">
                              {t('bonus.download.disclaimer')}
                            </p>
                          </div>
                        )}
                      </div>
                    </div>

                    {/* Right Column: Template Details and Features */}
                    <div className="lg:col-span-3 p-8 bg-white">
                      {/* Detailed Description */}
                      <div className="mb-8">
                        <h4 className="text-lg font-semibold text-gray-900 mb-4">
                          {t('bonus.sections.details')}
                        </h4>
                        <p className="text-gray-600 leading-relaxed">
                          {template.sideDescription}
                        </p>
                      </div>

                      {/* Features List */}
                      <div className="space-y-6">
                        <h4 className="text-lg font-semibold text-gray-900">
                          {t('bonus.sections.included')}
                        </h4>
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
                          {template.features.map((feature) => (
                            <div key={feature} className="flex items-center gap-3">
                              <div className="flex-shrink-0 h-6 w-6 rounded-full bg-[#fff1f1] flex items-center justify-center">
                                <Check className="h-4 w-4 text-[#690f39]" />
                              </div>
                              <span className="text-gray-600">{feature}</span>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </Card>
              </motion.div>
            ))}
          </div>
        </section>

        {/* Download Modal: Appears when a template is selected */}
        {selectedTemplate && (
          <DownloadModal
            isOpen={isModalOpen}
            onClose={() => {
              setIsModalOpen(false)
              setSelectedTemplate(null)
            }}
            templateId={selectedTemplate.id}
            templateName={selectedTemplate.title}
          />
        )}
      </div>
    </>
  )
}
