import React from "react";
import { Hero } from "../components/hero";
import { WhyChooseUs } from "../components/services";
import { About } from "../components/about-section";
import { Contact } from "../components/contact";
import { Testimonials } from "../components/testimonials";
import { Seo } from "../components/ui/seo";

export function HomePage() {
    const websiteSchema = {
        "@context": "https://schema.org",
        "@type": "WebSite",
        "name": "Lex Connect",
        "url": "https://www.lexconnect.ch"
    };

    return (
        <>
            <Seo 
                title="Lex Connect | Legal Services in Switzerland"
                description="Legal services and consulting for businesses in Switzerland. Expert guidance in business law, contracts, and compliance."
                path="/"
                schema={websiteSchema}
            />
            <main>
                <Hero />
                <WhyChooseUs />
                <Testimonials />
                <About />
                <Contact />
            </main>
        </>
    );
}
