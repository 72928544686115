interface ContactFormData {
  firstName: string
  lastName: string
  email: string
  services: {
    legal: boolean
    language: boolean
  }
  message: string
  language: string
}

export const contactService = {
  async submitForm(formData: ContactFormData): Promise<void> {
    const response = await fetch(`${process.env.REACT_APP_API_URL}contact/submit`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(formData),
    })

    if (!response.ok) {
      const error = await response.json()
      throw new Error(error.message || 'Failed to submit form')
    }
  }
}
