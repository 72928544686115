import React from 'react'
import { motion } from 'framer-motion'
import { Card } from '../components/ui/card'
import { useTranslation } from '../context/TranslationContext'
import { Link } from 'react-router-dom'
import {
  Globe,
  Building2,
  Scale,
  FileText,
  AlertCircle,
  Shield,
  Lock,
  Gavel,
  Languages,
  Link2
} from 'lucide-react'

// Helper function to convert markdown-style text to JSX with links
const formatText = (text: string) => {
  const parts = text.split(/\*(.*?)\*/)
  return parts.map((part, index) => {
    if (index % 2 === 0) {
      return part
    } else {
      // Check if the bold text should be a link
      const lowercaseText = part.toLowerCase()
      if (lowercaseText.includes('politique de confidentialité') || lowercaseText.includes('privacy policy')) {
        return (
          <Link
            key={index}
            to="/privacy"
            className="font-bold text-[#690f39] hover:text-[#8b1d4c]"
          >
            {part}
          </Link>
        )
      }
      return <strong key={index}>{part}</strong>
    }
  })
}

export function LegalNoticesPage() {
  const { t } = useTranslation()

  // Add email decoding function for consistency with other pages
  const decodeEmail = (encodedEmail: string) => {
    return encodedEmail.replace(/\[at\]/g, '@').replace(/\[dot\]/g, '.');
  }

  // Move formatContent inside the component
  const formatContent = (content: string | string[]) => {
    if (Array.isArray(content)) {
      return content.map((line, i) => {
        // Check if line contains [email]
        if (line.includes('[email]')) {
          const emailRegex = /\[email\](.*?)\[\/email\]/;
          const match = line.match(emailRegex);
          if (match) {
            const encodedEmail = match[1];
            const parts = line.split(/\[email\].*?\[\/email\]/);
            return (
              <p key={i} className="text-gray-600">
                {parts[0]}
                <span className="select-all">{decodeEmail(encodedEmail)}</span>
                {parts[1]}
              </p>
            );
          }
        }
        return (
          <p key={i} className="text-gray-600">
            {formatText(line)}
          </p>
        );
      });
    }
    return formatText(content);
  }

  // Icons for each section
  const icons = {
    site: <Globe className="h-6 w-6" />,
    owner: <Building2 className="h-6 w-6" />,
    hosting: <Shield className="h-6 w-6" />,
    status: <Scale className="h-6 w-6" />,
    intellectual: <FileText className="h-6 w-6" />,
    liability: <AlertCircle className="h-6 w-6" />,
    dataProtection: <Lock className="h-6 w-6" />,
    jurisdiction: <Gavel className="h-6 w-6" />,
    languages: <Languages className="h-6 w-6" />,
    updated: <Link2 className="h-6 w-6" />
  }

  // Sections to be displayed in cards
  const sections = [
    'site',
    'owner',
    'hosting',
    'intellectual',
    'status',
    'liability',
    'dataProtection',
    'jurisdiction',
    'languages',
    'updated'
  ]

  return (
    <div className="bg-gray-50">
      {/* Hero Section */}
      <section className="py-20">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
            className="text-center"
          >
            <h1 className="text-4xl font-bold text-gray-900 tracking-tight sm:text-5xl">
              {t('legalNotices.hero.title')}
            </h1>
          </motion.div>
        </div>
      </section>

      {/* Cards Grid Section */}
      <section className="py-4 pb-16">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            {sections.map((section, index) => (
              <motion.div
                key={section}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: index * 0.1 }}
              >
                <Card className="bg-white rounded-2xl shadow-sm p-8 h-full">
                  <div className="flex items-center gap-4 mb-4">
                    <div className="p-2 rounded-lg bg-[#fff1f1]">
                      <div className="text-[#690f39]">
                        {icons[section as keyof typeof icons]}
                      </div>
                    </div>
                    <h2 className="text-xl font-semibold text-gray-900">
                      {t(`legalNotices.sections.${section}.title`)}
                    </h2>
                  </div>
                  <div className="text-gray-600 space-y-2">
                    {formatContent(t(`legalNotices.sections.${section}.content`, { returnObjects: true }) as string | string[])}
                  </div>
                </Card>
              </motion.div>
            ))}
          </div>
        </div>
      </section>
    </div>
  )
}
