"use client";

import React, { useEffect, useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { ChevronLeft, ChevronRight, Quote } from "lucide-react";
import { useTranslation } from "../context/TranslationContext";

interface Testimonial {
    content: string;
    author: string;
}

export function Testimonials() {
    const [[page, direction], setPage] = useState([0, 0]);
    const { t } = useTranslation();

    const testimonials: Testimonial[] = [
        {
            content: t("testimonials.content.0") as string,
            author: "Bhakti",
        },
        {
            content: t("testimonials.content.1") as string,
            author: "Anaëlle",
        },
        {
            content: t("testimonials.content.2") as string,
            author: "Laetitia",
        },
    ];

    useEffect(() => {
        const advanceSlide = () => {
            setPage(([prevPage]) => [(prevPage + 1) % testimonials.length, 1]);
        };

        const id = window.setTimeout(advanceSlide, 8000);
        return () => window.clearTimeout(id);
    }, [page, testimonials.length]);

    const handleArrowClick = (direction: 1 | -1) => {
        const newPage =
            (page + direction + testimonials.length) % testimonials.length;
        setPage([newPage, direction]);
    };

    const slideVariants = {
        enter: (direction: number) => ({
            x: direction > 0 ? 100 : -100,
            opacity: 0,
        }),
        center: {
            zIndex: 1,
            x: 0,
            opacity: 1,
        },
        exit: (direction: number) => ({
            zIndex: 0,
            x: direction < 0 ? 100 : -100,
            opacity: 0,
        }),
    };

    return (
        <section className="py-12 bg-gray-50">
            <div className="max-w-4xl mx-auto px-4 flex flex-col">
                <h2 className="text-3xl sm:text-4xl font-bold text-center text-gray-900 mb-12">
                    {t("testimonials.title")}
                </h2>

                <div className="relative flex items-center h-[300px]">
                    <button
                        onClick={() => handleArrowClick(-1)}
                        className="absolute -left-2 md:-left-2 lg:-left-8 z-10 p-2 text-[#690f39] hover:text-[#690f39]/80 transition-colors -translate-y-4"
                        aria-label="Previous testimonial"
                    >
                        <ChevronLeft className="w-6 h-6" />
                    </button>

                    <div className="w-full h-full flex items-center">
                        <AnimatePresence
                            initial={false}
                            custom={direction}
                            mode="wait"
                        >
                            <motion.div
                                key={page}
                                custom={direction}
                                variants={slideVariants}
                                initial="enter"
                                animate="center"
                                exit="exit"
                                transition={{
                                    x: {
                                        type: "spring",
                                        stiffness: 100,
                                        damping: 20,
                                        duration: 0.8,
                                    },
                                    opacity: { duration: 0.5 },
                                }}
                                className="w-full"
                            >
                                <div className="text-center px-8 md:px-16">
                                    <p className="text-gray-600 text-md md:text-lg leading-relaxed mb-8">
                                        <Quote className="inline-block h-6 w-6 text-[#690f39] opacity-80 transform scale-x-[-1] mr-2" />
                                        {testimonials[page].content}
                                        <Quote className="inline-block h-6 w-6 text-[#690f39] opacity-80 ml-2" />
                                    </p>
                                    <div className="flex items-center justify-center gap-3">
                                        <div className="h-px w-12 bg-[#690f39]/20" />
                                        <span className="text-[#690f39] font-medium">
                                            {testimonials[page].author}
                                        </span>
                                        <div className="h-px w-12 bg-[#690f39]/20" />
                                    </div>
                                </div>
                            </motion.div>
                        </AnimatePresence>
                    </div>

                    <button
                        onClick={() => handleArrowClick(1)}
                        className="absolute -right-2 md:-right-2 lg:-right-8 z-10 p-2 text-[#690f39] hover:text-[#690f39]/80 transition-colors -translate-y-4"
                        aria-label="Next testimonial"
                    >
                        <ChevronRight className="w-6 h-6" />
                    </button>
                </div>
            </div>
        </section>
    );
}
