'use client'

import { motion } from 'framer-motion'
import { Button } from '../components/ui/button'
import { Card } from '../components/ui/card'
import {
  MapPin,
  Mail,
  GraduationCap,
  Languages,
  Plane,
  MessageSquare,
  ChevronRight,
  Laptop,
  Quote
} from 'lucide-react'
import { Link } from 'react-router-dom'
import { useTranslation } from '../context/TranslationContext'
import { Seo } from "../components/ui/seo";

export function AboutPage() {
  // Initialize translation hook for i18n support
  const { t } = useTranslation()

  // Split the languages description into bullet points with proper type handling
  const languagesText = t('about.cards.languages.description')
  const languagesList = typeof languagesText === 'string'
    ? languagesText.split(',').map((lang: string) => lang.trim())
    : []

  // Add email decoding function
  const decodeEmail = (encodedEmail: string) => {
    return encodedEmail.replace(/\[at\]/g, '@').replace(/\[dot\]/g, '.');
  }

  function renderEmail() {
    const encodedEmail = String(t('about.hero.contact.email'));
    return (
      <span className="select-all">{decodeEmail(encodedEmail)}</span>
    );
  }

  const websiteSchema = {
    "@context": "https://schema.org",
    "@type": "WebPage",
    "name": "About Lex Connect",
    "url": "https://www.lexconnect.ch/about"
  };

  return (
    <>
      <Seo 
        title="About Lex Connect | Legal Services Switzerland"
        description="Expert legal consulting services for businesses in Switzerland. Specializing in business law, contracts, and compliance with multilingual capabilities."
        path="/about"
        schema={websiteSchema}
      />
      <div className="bg-white">
        {/* Hero Section: Full-screen parallax section with background image */}
        <section className="relative h-[90vh] overflow-hidden">
          {/* Background Image Container with Gradient Overlay */}
          <div className="absolute inset-0">
            <picture>
              <source srcSet="/images/about-hero-copy.webp" type="image/webp" />
              <img
                src="/images/about-hero-copy.jpg"
                alt="Lausanne lake and skyline"
                className="w-full h-full object-cover"
              />
            </picture>
            {/* Gradient overlay for better text readability */}
            <div className="absolute inset-0 bg-gradient-to-b from-gray-50/100 to-black/0 h-1/4" />
          </div>

          {/* Hero Content */}
          <div className="relative h-full flex items-center">
            <div className="mx-auto max-w-7xl px-6 lg:px-8">
              <div className="grid grid-cols-1 lg:grid-cols-2 gap-12 items-center">
                {/* Left Column: Main Title and CTA */}
                <motion.div
                  initial={{ opacity: 0, x: -50 }}
                  animate={{ opacity: 1, x: 0 }}
                  transition={{ duration: 0.8 }}
                  className="text-white"
                >
                  <h1 className="text-6xl font-bold tracking-tight text-white mb-1">
                    {t('about.hero.title')}
                  </h1>
                  <p className="text-xl text-white/90 mb-8">
                    {t('about.hero.subtitle')}
                  </p>
                  <div className="flex gap-4">
                    <Link to="/contact">
                      <Button size="lg" variant="default">
                        {t('about.hero.contactcta')}
                      </Button>
                    </Link>
                  </div>
                </motion.div>

                {/* Right Column: Contact Information Card */}
                <motion.div
                  initial={{ opacity: 0, scale: 0.9 }}
                  animate={{ opacity: 1, scale: 1 }}
                  transition={{ duration: 0.8 }}
                  className="relative hidden lg:block"
                >
                  <Card className="bg-white/10 backdrop-blur-md p-6 text-white border-white/20">
                    <div className="space-y-4">
                      {/* Contact Details with Icons */}
                      <div className="flex items-center gap-3">
                        <MapPin className="h-5 w-5 text-white/80" />
                        <span>{t('about.hero.contact.location')}</span>
                      </div>
                      <div className="flex items-center gap-3">
                        <Laptop className="h-5 w-5 text-white/80" />
                        <span>{t('about.hero.contact.virtual')}</span>
                      </div>
                      <div className="flex items-center gap-3">
                        <Mail className="h-5 w-5 text-white/80" />
                        {renderEmail()}
                      </div>

                    </div>
                  </Card>
                </motion.div>
              </div>
            </div>
          </div>
        </section>

        {/* Personal Introduction Section: Two-column layout */}
        <section className="py-24 bg-white">
          <div className="mx-auto max-w-7xl px-6 lg:px-8">
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-16 items-center">
              {/* Left Column: Journey Text */}
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.8 }}
              >
                <h2 className="text-3xl font-bold text-gray-900 mb-6">{t('about.journey.title')}</h2>
                {/* Safely handle array of paragraphs with type checking and justified text */}
                <div className="space-y-6 text-gray-600 text-lg text-justify">
                  {Array.isArray(t('about.journey.paragraphs', { returnObjects: true }))
                    ? (t('about.journey.paragraphs', { returnObjects: true }) as string[]).map((paragraph: string, index: number) => (
                        <p key={index}>{paragraph}</p>
                      ))
                    : <p>{t('about.journey.paragraphs')}</p>
                  }
                </div>
              </motion.div>

              {/* Right Column: Grid of Feature Cards */}
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                whileInView={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.8, delay: 0.2 }}
                className="grid grid-cols-2 gap-8"
              >
                {/* Education Card */}
                <Card className="p-6 mt-8 bg-gray-50">
                  <GraduationCap className="h-8 w-8 text-[#690f39] mb-4" />
                  <h3 className="font-semibold mb-2">{t('about.cards.education.title')}</h3>
                  <p className="text-md text-gray-600">{t('about.cards.education.description')}</p>
                </Card>
                {/* Languages Card */}
                <Card className="p-6 mt-8 bg-gray-50">
                  <Languages className="h-8 w-8 text-[#690f39] mb-4" />
                  <h3 className="font-semibold mb-2">{t('about.cards.languages.title')}</h3>
                  <ul className="text-gray-600 list-disc pl-4 space-y-1">
                    {languagesList.map((lang: string, index: number) => (
                      <li key={index}>{lang}</li>
                    ))}
                  </ul>
                </Card>
                {/* Digital Nomad Card */}
                <Card className="p-6 bg-gray-50">
                  <Plane className="h-8 w-8 text-[#690f39] mb-4" />
                  <h3 className="font-semibold mb-2">{t('about.cards.digitalNomad.title')}</h3>
                  <p className="text-md text-gray-600">{t('about.cards.digitalNomad.description')}</p>
                </Card>
                {/* Communication Card */}
                <Card className="p-6 bg-gray-50">
                  <MessageSquare className="h-8 w-8 text-[#690f39] mb-4" />
                  <h3 className="font-semibold mb-2">{t('about.cards.communication.title')}</h3>
                  <p className="text-md text-gray-600">{t('about.cards.communication.description')}</p>
                </Card>
              </motion.div>
            </div>
          </div>
        </section>

        {/* Timeline Section */}
        <section className="py-24 bg-gray-50">
          <div className="mx-auto max-w-4xl px-6 lg:px-8">
            <div className="relative">
              {/* Central Timeline Line with Gradient and Glow */}
              <div
                className="absolute left-4 md:left-1/2 h-full w-0.5 transform md:-translate-x-1/2"
                style={{
                  background: 'linear-gradient(to bottom, #690f39 0%, #690f39 90%, transparent 100%)',
                  boxShadow: '0 0 8px rgba(105, 15, 57, 0.3)'
                }}
              />

              <div className="space-y-16">
                {/* Office */}
                <motion.div
                  initial={{ opacity: 0, x: -20 }}
                  whileInView={{ opacity: 1, x: 0 }}
                  transition={{ duration: 0.8 }}
                  className="relative flex items-center justify-between flex-col md:flex-row"
                >
                  {/* Content - Full width on mobile, Left side on desktop */}
                  <div className="w-full pl-12 md:pl-0 md:w-[45%]">
                    <div className="bg-white rounded-xl shadow-lg p-8 border-t-4 border-[#690f39] hover:shadow-xl transition-shadow duration-300">
                      <h3 className="text-2xl font-bold text-gray-900 mb-4 bg-gradient-to-r from-[#690f39] to-[#8b1d4c] bg-clip-text text-transparent">
                        {t('about.timeline.office.title')}
                      </h3>
                      <p className="text-lg text-gray-600 leading-relaxed">
                        {t('about.timeline.office.description')}
                      </p>
                    </div>
                  </div>
                  {/* Right Side - Hidden on mobile */}
                  <div className="hidden md:block md:w-[45%]" />
                </motion.div>

                {/* Challenges */}
                <motion.div
                  initial={{ opacity: 0, x: 20 }}
                  whileInView={{ opacity: 1, x: 0 }}
                  transition={{ duration: 0.8 }}
                  className="relative flex items-center justify-between flex-col md:flex-row"
                >
                  {/* Left Side - Hidden on mobile */}
                  <div className="hidden md:block md:w-[45%]" />
                  {/* Content - Full width on mobile, Right side on desktop */}
                  <div className="w-full pl-12 md:pl-0 md:w-[45%]">
                    <div className="bg-white rounded-xl shadow-lg p-8 border-t-4 border-[#690f39] hover:shadow-xl transition-shadow duration-300">
                      <h3 className="text-2xl font-bold text-gray-900 mb-4 bg-gradient-to-r from-[#690f39] to-[#8b1d4c] bg-clip-text text-transparent">
                        {t('about.timeline.challenges.title')}
                      </h3>
                      <p className="text-lg text-gray-600 leading-relaxed">
                        {t('about.timeline.challenges.description')}
                      </p>
                    </div>
                  </div>
                </motion.div>

                {/* Partnership */}
                <motion.div
                  initial={{ opacity: 0, x: -20 }}
                  whileInView={{ opacity: 1, x: 0 }}
                  transition={{ duration: 0.8 }}
                  className="relative flex items-center justify-between flex-col md:flex-row"
                >
                  {/* Content - Full width on mobile, Left side on desktop */}
                  <div className="w-full pl-12 md:pl-0 md:w-[45%]">
                    <div className="bg-white rounded-xl shadow-lg p-8 border-t-4 border-[#690f39] hover:shadow-xl transition-shadow duration-300">
                      <h3 className="text-2xl font-bold text-gray-900 mb-4 bg-gradient-to-r from-[#690f39] to-[#8b1d4c] bg-clip-text text-transparent">
                        {t('about.timeline.partnership.title')}
                      </h3>
                      <p className="text-lg text-gray-600 leading-relaxed">
                        {t('about.timeline.partnership.description')}
                      </p>
                    </div>
                  </div>
                  {/* Right Side - Hidden on mobile */}
                  <div className="hidden md:block md:w-[45%]" />
                </motion.div>
              </div>
            </div>
          </div>
        </section>

        {/* Personal Quote Section with Decorative Quotes */}
        <section className="py-24 bg-white">
          <div className="mx-auto max-w-7xl px-6 lg:px-8">
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8 }}
              className="max-w-3xl mx-auto text-center relative"
            >
              {/* Decorative quote marks */}
              <Quote className="h-8 w-8 text-[#690f39] absolute -top-4 -left-4 transform -rotate-12" />
              <Quote className="h-8 w-8 text-[#690f39] absolute -bottom-4 -right-4 transform rotate-180 rotate-12" />
              <blockquote className="text-xl text-gray-900 italic px-8">
                {t('about.quote')}
              </blockquote>
            </motion.div>
          </div>
        </section>

        {/* CTA Section: Final call-to-action */}
        <section className="pt-20 pb-12 bg-gray-50">
          <div className="mx-auto max-w-7xl px-6 lg:px-8">
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.8 }}
              className="text-center"
            >
              <h2 className="text-3xl font-bold text-gray-900 mb-6">
                {t('about.cta.title')}
              </h2>
              <p className="text-xl text-gray-600 mb-8 max-w-2xl mx-auto">
                {t('about.cta.description')}
              </p>
              <Link to="/contact">
                <Button
                  size="lg"
                  variant="outline"
                  className="bg-[#690f39] hover:bg-[#8b1d4c] text-white hover:text-white rounded-full px-8"
                >
                  {t('about.cta.button')}
                  <ChevronRight className="ml-2 h-5 w-5" />
                </Button>
              </Link>
            </motion.div>
          </div>
        </section>
      </div>
    </>
  )
}
