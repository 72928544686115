import React from 'react'

interface CheckboxProps extends React.InputHTMLAttributes<HTMLInputElement> {
  id: string
}

/**
 * Custom Checkbox component with burgundy styling
 * Overrides default browser checkbox appearance
 */
export function Checkbox({ id, ...props }: CheckboxProps) {
  return (
    <input
      type="checkbox"
      id={id}
      className="h-4 w-4 rounded border-gray-300
        accent-[#690f39]
        checked:bg-[#690f39]
        checked:hover:bg-[#8b1d4c]
        checked:focus:bg-[#690f39]
        hover:bg-[#fff1f1]
        focus:ring-[#690f39]
        focus:ring-offset-0"
      {...props}
    />
  )
}
