import React from "react";
import { useTranslation } from "../context/TranslationContext";

/**
 * Language Switcher Component
 * Provides UI for switching between English and French
 * Features:
 * - Visual indication of current language
 * - Hover effects for better UX
 * - Persists language choice in localStorage
 */
export function LanguageSwitcher() {
    const { language, setLanguage } = useTranslation();

    const handleFrenchClick = () => setLanguage("fr");
    const handleEnglishClick = () => setLanguage("en");

    return (
        <div className="flex items-center text-sm">
            {/* French Language Button */}
            <button
                onClick={handleFrenchClick}
                className={`px-2 py-1 transition-colors ${
                    // Apply active/inactive styles based on current language
                    language === "fr"
                        ? "text-white font-medium" // Active state
                        : "text-[#ffd4d4] hover:text-white" // Inactive state with hover
                }`}
            >
                FR
            </button>

            {/* Separator */}
            <span className="text-[#ffd4d4]">|</span>

            {/* English Language Button */}
            <button
                onClick={handleEnglishClick}
                className={`px-2 py-1 transition-colors ${
                    // Apply active/inactive styles based on current language
                    language === "en"
                        ? "text-white font-medium" // Active state
                        : "text-[#ffd4d4] hover:text-white" // Inactive state with hover
                }`}
            >
                EN
            </button>
        </div>
    );
}
