"use client";

import React, { useState } from "react";
import { motion } from "framer-motion";
import { Button } from "./ui/button";
import { Input } from "./ui/input";
import { Textarea } from "./ui/textarea";
import { MapPin, Mail, Loader2 } from "lucide-react";
import { contactService } from "../services/contactService";
import { useToast } from "./ui/toast";
import { useTranslation } from "../context/TranslationContext";
import { Checkbox } from "./ui/checkbox";

/**
 * Contact form component for homepage
 * Handles form submission, validation, and loading states
 * Supports localization for both English and French
 */
export function Contact() {
    const { t, language } = useTranslation();
    const { toast } = useToast();
    const [isSubmitting, setIsSubmitting] = useState(false);

    // Form state with TypeScript interface for type safety
    const [formState, setFormState] = useState({
        firstName: "",
        lastName: "",
        email: "",
        services: {
            legal: true,
            language: false,
            other: false,
        },
        message: "",
        privacyAccepted: false,
    });

    /**
     * Validates email format using regex
     * @param email - Email address to validate
     * @returns boolean indicating if email format is valid
     */
    const validateEmail = (email: string) => {
        return email.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/);
    };

    // Add email decoding function
    const decodeEmail = (encodedEmail: string) => {
        return encodedEmail.replace(/\[at\]/g, "@").replace(/\[dot\]/g, ".");
    };

    function renderEmail() {
        const encodedEmail = String(t("contact.info.email.value"));
        return <span className="select-all">{decodeEmail(encodedEmail)}</span>;
    }

    /**
     * Handles form submission with validation
     * Shows loading state during submission
     * Displays success/error messages via toast
     */
    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        setIsSubmitting(true);

        // Validate required fields
        if (
            !formState.firstName ||
            !formState.lastName ||
            !formState.email ||
            !formState.message
        ) {
            toast({
                title: t("contact.validation.required") as string,
                description: t("contact.validation.required") as string,
                variant: "destructive",
            });
            setIsSubmitting(false);
            return;
        }

        // Validate email format
        if (!validateEmail(formState.email)) {
            toast({
                title: t("contact.validation.email") as string,
                description: t("contact.validation.email") as string,
                variant: "destructive",
            });
            setIsSubmitting(false);
            return;
        }

        // Validate privacy policy acceptance
        if (!formState.privacyAccepted) {
            toast({
                title: t("contactp.form.privacyError") as string,
                description: t("contactp.form.privacyRequired") as string,
                variant: "destructive",
            });
            setIsSubmitting(false);
            return;
        }

        try {
            // Submit form with current language preference and default services
            await contactService.submitForm({ ...formState, language });

            // Show success message
            toast({
                title: t("contact.success.title") as string,
                description: t("contact.success.description") as string,
            });

            // Reset form after successful submission
            setFormState({
                firstName: "",
                lastName: "",
                email: "",
                services: { legal: true, language: false, other: false },
                message: "",
                privacyAccepted: false,
            });
        } catch (error) {
            // Show error message if submission fails
            toast({
                title: t("contact.error.title") as string,
                description: t("contact.error.description") as string,
                variant: "destructive",
            });
        } finally {
            setIsSubmitting(false);
        }
    };

    // Add these before the return statement
    const handleFirstNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFormState((prev) => ({ ...prev, firstName: e.target.value }));
    };

    const handleLastNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFormState((prev) => ({ ...prev, lastName: e.target.value }));
    };

    const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFormState((prev) => ({ ...prev, email: e.target.value }));
    };

    const handleMessageChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setFormState((prev) => ({ ...prev, message: e.target.value }));
    };

    return (
        <section id="contact-section" className="py-20 bg-gray-50">
            <div className="mx-auto max-w-7xl px-6 lg:px-8">
                <div className="grid grid-cols-1 gap-16 lg:grid-cols-2">
                    {/* Left Column: Contact Information */}
                    <motion.div
                        initial={{ opacity: 0, x: -20 }}
                        whileInView={{ opacity: 1, x: 0 }}
                        transition={{ duration: 0.8 }}
                    >
                        <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                            {t("contact.title")}
                        </h2>
                        <p className="mt-4 text-lg text-gray-600">
                            {t("contact.subtitle")}
                        </p>

                        {/* Contact Details */}
                        <div className="mt-8 space-y-6">
                            {/* Location Information */}
                            <div className="flex items-center gap-4">
                                <MapPin className="h-6 w-6 text-[#690f39]" />
                                <div>
                                    <h3 className="font-semibold">
                                        {t("contact.info.location.title")}
                                    </h3>
                                    <p className="text-gray-600">
                                        {t("contact.info.location.value")}
                                    </p>
                                </div>
                            </div>

                            {/* Email Information */}
                            <div className="flex items-center gap-4">
                                <Mail className="h-6 w-6 text-[#690f39]" />
                                <div>
                                    <h3 className="font-semibold">
                                        {t("contact.info.email.title")}
                                    </h3>
                                    <p className="text-gray-600">
                                        {renderEmail()}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </motion.div>

                    {/* Right Column: Contact Form */}
                    <motion.div
                        initial={{ opacity: 0, x: 20 }}
                        whileInView={{ opacity: 1, x: 0 }}
                        transition={{ duration: 0.8 }}
                        className="bg-white rounded-2xl shadow-lg p-8"
                    >
                        {/* Form Fields */}
                        <form onSubmit={handleSubmit} className="space-y-6">
                            <div className="grid grid-cols-1 gap-6 sm:grid-cols-2">
                                <div>
                                    <label className="block text-sm font-medium text-gray-700">
                                        {t("contact.form.firstName")}
                                    </label>
                                    <Input
                                        required
                                        value={formState.firstName}
                                        onChange={handleFirstNameChange}
                                        placeholder={
                                            t(
                                                "contact.form.placeholders.firstName"
                                            ) as string
                                        }
                                        className="mt-1"
                                    />
                                </div>
                                <div>
                                    <label className="block text-sm font-medium text-gray-700">
                                        {t("contact.form.lastName")}
                                    </label>
                                    <Input
                                        required
                                        value={formState.lastName}
                                        onChange={handleLastNameChange}
                                        placeholder={
                                            t(
                                                "contact.form.placeholders.lastName"
                                            ) as string
                                        }
                                        className="mt-1"
                                    />
                                </div>
                            </div>

                            <div>
                                <label className="block text-sm font-medium text-gray-700">
                                    {t("contact.form.email")}
                                </label>
                                <Input
                                    required
                                    type="email"
                                    value={formState.email}
                                    onChange={handleEmailChange}
                                    placeholder={
                                        t(
                                            "contact.form.placeholders.email"
                                        ) as string
                                    }
                                    className="mt-1"
                                />
                            </div>

                            <div>
                                <label className="block text-sm font-medium text-gray-700">
                                    {t("contact.form.message")}
                                </label>
                                <Textarea
                                    required
                                    value={formState.message}
                                    onChange={handleMessageChange}
                                    placeholder={
                                        t(
                                            "contact.form.placeholders.message"
                                        ) as string
                                    }
                                    className="mt-1"
                                    rows={4}
                                />
                            </div>

                            {/* Privacy Policy Checkbox */}
                            <div className="flex items-center space-x-2">
                                <Checkbox
                                    id="privacy-policy"
                                    checked={formState.privacyAccepted}
                                    onChange={(e) =>
                                        setFormState((prev) => ({
                                            ...prev,
                                            privacyAccepted: e.target.checked,
                                        }))
                                    }
                                />
                                <label
                                    htmlFor="privacy-policy"
                                    className="text-sm text-gray-600"
                                >
                                    {t("contactp.form.privacyAccept.before")}{" "}
                                    <a
                                        href="/privacy"
                                        className="text-[#690f39] hover:underline"
                                    >
                                        {t("contactp.form.privacyAccept.link")}
                                    </a>
                                    {t("contactp.form.privacyAccept.after")}
                                </label>
                            </div>

                            <Button
                                type="submit"
                                className="w-full bg-[#690f39] hover:bg-[#8b1d4c]"
                                disabled={isSubmitting}
                            >
                                {isSubmitting ? (
                                    <>
                                        <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                                        {t("contact.form.submitting")}
                                    </>
                                ) : (
                                    t("contact.form.submit")
                                )}
                            </Button>
                        </form>
                    </motion.div>
                </div>
            </div>
        </section>
    );
}
