import React from "react";
import {
    BrowserRouter as Router,
    Routes,
    Route,
    Navigate,
} from "react-router-dom";
import { HomePage } from "./pages/home";
import { AboutPage } from "./pages/about";
import { Header } from "./components/header";
import { Footer } from "./components/footer";
import { TemplatesPage } from "./pages/templates";
import { LegalPage } from "./pages/legal";
import { LanguagePage } from "./pages/language";
import { ContactPage } from "./pages/contact-page";
import { BonusPage } from "./pages/bonus";
import { useScrollToTop } from "./hooks/useScrollToTop";
import { PrivacyPage } from "./pages/privacy";
import { TermsPage } from "./pages/terms";
import { LegalNoticesPage } from "./pages/legal-notices";
import { TranslationProvider } from "./context/TranslationContext";
import { ToastProvider } from "./components/ui/toast";
import { SuccessPage } from "./pages/success";
import { SchedulePage } from "./pages/schedule";
import { DownloadPage } from "./pages/download";

function ScrollToTop() {
    useScrollToTop();
    return null;
}

function App() {
    return (
        <ToastProvider>
            <TranslationProvider>
                <Router
                    future={{
                        v7_startTransition: true,
                        v7_relativeSplatPath: true,
                    }}
                >
                    <ScrollToTop />
                    <div className="min-h-screen bg-gradient-to-b from-white to-gray-50">
                        <Header />
                        <Routes>
                            <Route path="/" element={<HomePage />} />
                            <Route path="/about" element={<AboutPage />} />
                            <Route path="/legal" element={<LegalPage />} />
                            <Route
                                path="/language"
                                element={<LanguagePage />}
                            />
                            <Route
                                path="/templates"
                                element={<TemplatesPage />}
                            />
                            <Route path="/bonus" element={<BonusPage />} />
                            <Route path="/contact" element={<ContactPage />} />
                            <Route path="/privacy" element={<PrivacyPage />} />
                            <Route path="/terms" element={<TermsPage />} />
                            <Route
                                path="/legal-notices"
                                element={<LegalNoticesPage />}
                            />
                            <Route path="/success" element={<SuccessPage />} />
                            <Route
                                path="/schedule"
                                element={<SchedulePage />}
                            />
                            <Route
                                path="/download"
                                element={<Navigate to="/" replace />}
                            />
                            <Route
                                path="/download/:token"
                                element={<DownloadPage />}
                            />
                        </Routes>
                        <Footer />
                    </div>
                </Router>
            </TranslationProvider>
        </ToastProvider>
    );
}

export default App;
