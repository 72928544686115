"use client";

import React from "react";
import { motion } from "framer-motion";
import { Button } from "./ui/button";
import { Link } from "react-router-dom";
import SwissPattern from "../assets/swiss-pattern.svg";
import { useTranslation } from "../context/TranslationContext";

/**
 * Hero component for the landing page
 * Features:
 * - Animated text and buttons
 * - Swiss pattern background with mask effect
 * - Smooth scroll to contact section
 * - Responsive design
 */
export function Hero() {
    const { t } = useTranslation();

    /**
     * Smoothly scrolls to the contact section
     * Includes offset to account for fixed header
     */
    const scrollToContact = () => {
        const contactSection = document.getElementById("contact-section");
        if (!contactSection) return;

        const yOffset = -100;
        const y =
            contactSection.getBoundingClientRect().top +
            window.scrollY +
            yOffset;
        window.scrollTo({ top: y, behavior: "smooth" });
    };

    return (
        <div className="relative h-[85vh] overflow-hidden bg-gray-50">
            {/* Decorative Background Pattern */}
            <div
                className="absolute inset-0 opacity-5"
                style={{
                    backgroundImage: `url(${SwissPattern})`,
                    backgroundRepeat: "repeat",
                    backgroundSize: "60px",
                    backgroundPosition: "center top",
                    transform: "translateY(-30px) scale(1.1)",
                    maskImage:
                        "linear-gradient(to bottom, transparent 0%, black 10%, black 90%, transparent 100%)",
                    WebkitMaskImage:
                        "linear-gradient(to bottom, transparent 0%, black 10%, black 90%, transparent 100%)",
                }}
            />

            {/* Main Content Container */}
            <div className="relative mx-auto max-w-7xl px-6 h-full flex items-center">
                <motion.div
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.8 }}
                    className="text-center w-full"
                >
                    {/* Hero Title with Gradient Effect */}
                    <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">
                        <span className="block">{t("hero.title.part1")}</span>
                        <span className="block bg-gradient-to-r from-[#690f39] to-[#8b1d4c] bg-clip-text text-transparent pb-1">
                            {t("hero.title.part2")}
                        </span>
                    </h1>

                    {/* Hero Description */}
                    <p className="mx-auto mt-6 max-w-2xl text-lg leading-8 text-gray-600">
                        {t("hero.description")}
                    </p>

                    {/* Call to Action Buttons */}
                    <div className="mt-10 flex items-center justify-center gap-x-6">
                        <Button
                            size="lg"
                            className="rounded-full bg-[#690f39] hover:bg-[#8b1d4c]"
                            onClick={scrollToContact}
                        >
                            {t("hero.buttons.schedule")}
                        </Button>
                        <Link to="/about">
                            <Button
                                variant="outline"
                                size="lg"
                                className="rounded-full"
                            >
                                {t("hero.buttons.learnMore")}
                            </Button>
                        </Link>
                    </div>
                </motion.div>
            </div>
        </div>
    );
}
