import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { motion } from 'framer-motion'
import { Button } from '../components/ui/button'
import { FileText, Download, AlertCircle } from 'lucide-react'
import { useTranslation } from '../context/TranslationContext'
import { useToast } from '../components/ui/toast'

export function DownloadPage() {
  const { token } = useParams()
  const { t } = useTranslation()
  const { toast } = useToast()
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState<string | null>(null)
  const [downloadAttempts, setDownloadAttempts] = useState<number | null>(null)
  const [maxDownloads, setMaxDownloads] = useState<number | null>(null)

  const handleDownload = async () => {
    try {
      setIsLoading(true)
      const response = await fetch(`${process.env.REACT_APP_API_URL}templates/download/${token}`, {
        method: 'GET',
      })

      if (!response.ok) {
        const errorData = await response.json()
        throw new Error(errorData.error || 'Download failed')
      }

      // Get filename from Content-Disposition header
      const disposition = response.headers.get('content-disposition')
      const filename = disposition?.split('filename=')[1]?.replace(/"/g, '') || 'template.pdf'

      // Create blob and trigger download
      const blob = await response.blob()
      const url = window.URL.createObjectURL(blob)
      const a = document.createElement('a')
      a.href = url
      a.download = filename
      document.body.appendChild(a)
      a.click()
      window.URL.revokeObjectURL(url)
      document.body.removeChild(a)

      toast({
        title: t('download.toast.success.title') as string,
        description: t('download.toast.success.description') as string,
      })

      // Update download attempts count
      setDownloadAttempts((prev) => prev !== null ? prev + 1 : null)
    } catch (error) {
      console.error('Download error:', error)
      setError(error instanceof Error ? error.message : 'Download failed')
      toast({
        title: t('download.toast.error.title') as string,
        description: t('download.toast.error.description') as string,
        variant: "destructive",
      })
    } finally {
      setIsLoading(false)
    }
  }

  // Fetch download information on page load
  useEffect(() => {
    const fetchDownloadInfo = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}templates/download-info/${token}`)
        if (!response.ok) {
          const errorData = await response.json()
          throw new Error(errorData.error)
        }
        const data = await response.json()
        setDownloadAttempts(data.downloadCount)
        setMaxDownloads(data.maxDownloads)
      } catch (error) {
        console.error('Error fetching download info:', error)
        setError(error instanceof Error ? error.message : 'Failed to load download information')
      }
    }

    if (token) {
      fetchDownloadInfo()
    }
  }, [token])

  if (error) {
    return (
      <div className="min-h-[60vh] bg-gray-50 flex items-center">
        <div className="mx-auto max-w-7xl px-6 py-24 lg:px-8">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            className="text-center"
          >
            <div className="mx-auto h-12 w-12 flex items-center justify-center rounded-full bg-red-100">
              <AlertCircle className="h-6 w-6 text-red-600" />
            </div>
            <h1 className="mt-6 text-3xl font-bold tracking-tight text-gray-900">
              {t('download.error.title')}
            </h1>
            <p className="mt-4 text-lg text-gray-600">
              {error}
            </p>
          </motion.div>
        </div>
      </div>
    )
  }

  return (
    <div className="min-h-[60vh] bg-gray-50 flex items-center">
      <div className="mx-auto max-w-7xl px-6 py-24 lg:px-8">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="text-center"
        >
          <div className="mx-auto h-12 w-12 flex items-center justify-center rounded-full bg-[#fff1f1]">
            <FileText className="h-6 w-6 text-[#690f39]" />
          </div>
          <h1 className="mt-6 text-3xl font-bold tracking-tight text-gray-900">
            {t('download.title')}
          </h1>
          <p className="mt-4 text-lg text-gray-600">
            {t('download.description')}
          </p>

          {downloadAttempts !== null && maxDownloads !== null && (
            <p className="mt-2 text-sm text-gray-500">
              {t('download.attempts.label')}: {maxDownloads - downloadAttempts} {t('download.attempts.of')} {maxDownloads}
            </p>
          )}

          <div className="mt-10">
            <Button
              onClick={handleDownload}
              disabled={isLoading || (maxDownloads !== null && downloadAttempts !== null && downloadAttempts >= maxDownloads && maxDownloads !== -1)}
              className="bg-[#690f39] hover:bg-[#8b1d4c] text-white"
            >
              {isLoading ? (
                <>
                  {t('download.button.downloading')}
                  <Download className="ml-2 h-4 w-4 animate-bounce" />
                </>
              ) : (
                <>
                  {t('download.button.download')}
                  <Download className="ml-2 h-4 w-4" />
                </>
              )}
            </Button>
          </div>
        </motion.div>
      </div>
    </div>
  )
}
