import React, { useState } from 'react'
import { motion } from 'framer-motion'
import { Button } from '../components/ui/button'
import {
  Globe,
  Pen,
  FileText,
  PencilRuler,
  ChevronDown,
  ChevronUp
} from 'lucide-react'
import { useTranslation } from '../context/TranslationContext'
import { Link } from 'react-router-dom'

interface ServiceFeature {
  title: string;
  icon: string;
  frontDescription: string;
  backDescription: string;
  features: string[];
}

export function LanguagePage() {
  const [flippedCard, setFlippedCard] = useState<number | null>(null)
  const [openFaq, setOpenFaq] = useState<number | null>(null)
  const { t } = useTranslation()

  const services = Array.isArray(t('language.services', { returnObjects: true }))
    ? (t('language.services', { returnObjects: true }) as unknown as ServiceFeature[])
    : []

  const faqs = Array.isArray(t('language.faq.questions', { returnObjects: true }))
    ? (t('language.faq.questions', { returnObjects: true }) as unknown as Array<{ question: string; answer: string }>)
    : []

  const icons = [
    <FileText className="h-8 w-8 text-[#690f39]" />,
    <Pen className="h-8 w-8 text-[#690f39]" />,
    <Globe className="h-8 w-8 text-[#690f39]" />,
    <PencilRuler className="h-8 w-8 text-[#690f39]" />
  ]

  return (
    <div className="bg-gray-50">
      {/* Hero Section */}
      <section className="py-20">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
            className="text-center"
          >
            <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">
              {t('language.hero.title')}
            </h1>
            <p className="mt-6 text-lg leading-8 text-gray-600 max-w-2xl mx-auto">
              {t('language.hero.description')}
            </p>
          </motion.div>
        </div>
      </section>

      {/* Flip Cards Section */}
      <section className="pt-12 pb-24">
        <div className="mx-auto max-w-6xl px-6 lg:px-8">
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8 max-w-4xl mx-auto">
            {services.map((service, index) => (
              <div
                key={index}
                className="relative h-[240px] group"
                onMouseEnter={() => setFlippedCard(index)}
                onMouseLeave={() => setFlippedCard(null)}
              >
                <motion.div
                  className="absolute inset-0"
                  initial={false}
                  animate={{
                    rotateY: flippedCard === index ? 180 : 0
                  }}
                  transition={{ duration: 0.4 }}
                  style={{ transformStyle: 'preserve-3d' }}
                >
                  {/* Front of card */}
                  <div className="absolute inset-0 bg-white rounded-2xl p-8 flex flex-col items-center justify-center text-center backface-hidden shadow-sm">
                    <div className="w-16 h-16 bg-[#fff1f1] rounded-full flex items-center justify-center mb-4">
                      {icons[index]}
                    </div>
                    <h3 className="text-2xl font-semibold">{service.title}</h3>
                  </div>

                  {/* Back of card */}
                  <div
                    className="absolute inset-0 bg-gradient-to-br from-[#690f39] to-[#8b1d4c] text-white rounded-2xl p-8 flex flex-col items-center justify-center text-center backface-hidden shadow-sm"
                    style={{ transform: 'rotateY(180deg)' }}
                  >
                    <h3 className="text-2xl font-semibold mb-4">{service.title}</h3>
                    <p className="text-base">{service.backDescription}</p>
                  </div>
                </motion.div>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* FAQ Section */}
      <section className="py-24 bg-white">
        <div className="mx-auto max-w-3xl px-6 lg:px-8">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
            className="text-center mb-16"
          >
            <h2 className="text-3xl font-bold tracking-tight text-gray-900">
              {t('language.faq.title')}
            </h2>
          </motion.div>

          <div className="space-y-4">
            {faqs.map((faq, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: index * 0.1 }}
                className="border border-gray-200 rounded-lg overflow-hidden"
              >
                <button
                  onClick={() => setOpenFaq(openFaq === index ? null : index)}
                  className="w-full px-6 py-4 flex items-center justify-between bg-white hover:bg-gray-50 transition-colors"
                >
                  <span className="font-medium text-left text-gray-900">{faq.question}</span>
                  {openFaq === index ? (
                    <ChevronUp className="h-5 w-5 text-gray-500" />
                  ) : (
                    <ChevronDown className="h-5 w-5 text-gray-500" />
                  )}
                </button>
                {openFaq === index && (
                  <div className="px-6 py-4 bg-gray-50">
                    <p className="text-gray-600">{faq.answer}</p>
                  </div>
                )}
              </motion.div>
            ))}
          </div>
        </div>
      </section>

      {/* CTA Section */}
      <section className="py-16 bg-gray-50">
        <div className="mx-auto max-w-7xl px-6 lg:px-8 text-center">
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 mb-8">
            {t('language.cta.title')}
          </h2>
          <Link to="/contact">
            <Button size="lg" className="bg-[#690f39] hover:bg-[#8b1d4c] text-white">
              {t('language.cta.button')}
            </Button>
          </Link>
        </div>
      </section>
    </div>
  )
}
