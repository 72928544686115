import { useEffect } from 'react';

declare global {
  interface Window {
    ml: (...args: any[]) => void;
  }
}

const MailerLiteForm: React.FC = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.async = true;
    script.src = 'https://assets.mailerlite.com/js/universal.js';
    script.onload = () => {
      console.log('MailerLite script loaded');
      window.ml('account', '1408799');
    };
    script.onerror = () => console.error('Failed to load MailerLite script');
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  // Return null since we don’t need to render anything
  return null;
};

// Export a function to trigger the popup
export const showMailerLitePopup = () => {
  const popup = document.getElementById('mailerlite-popup');
  if (popup) {
    popup.style.display = 'flex';
  }
};

export default MailerLiteForm;
