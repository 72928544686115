import React, { useEffect } from 'react'
import { motion } from 'framer-motion'
import { useTranslation } from '../context/TranslationContext'

export function SchedulePage() {
  // Initialize translation hook
  const { t, language } = useTranslation()

  // Load and cleanup Calendly widget script
  useEffect(() => {
    // Create and append Calendly script to document
    const script = document.createElement('script')
    script.src = 'https://assets.calendly.com/assets/external/widget.js'
    script.async = true
    document.body.appendChild(script)

    // Cleanup function to remove script when component unmounts
    return () => {
      if (document.body.contains(script)) {
        document.body.removeChild(script)
      }
    }
  }, [])

  // Get Calendly URL with language parameter
  const getCalendlyUrl = () => {
    const baseUrl = "https://calendly.com/alexandra-lexconnect/15min"
    const langParam = language === 'fr' ? '?language=fr' : '?language=en'
    return baseUrl + langParam
  }

  return (
    <div className="bg-gray-50">
      {/* Hero Section: Title and Description */}
      <section className="py-20">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
            className="text-center"
          >
            <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">
              {t('schedule.hero.title')}
            </h1>
            <p className="mt-6 text-lg leading-8 text-gray-600 max-w-2xl mx-auto">
              {t('schedule.hero.description')}
            </p>
          </motion.div>
        </div>
      </section>

      {/* Calendly Integration Section */}
      <section className="pb-20">
        <div className="mx-auto max-w-6xl px-6 lg:px-8">
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.8, delay: 0.2 }}
            className="bg-white rounded-2xl shadow-lg overflow-hidden"
          >
            {/* Calendly Inline Widget */}
            <div
              className="calendly-inline-widget"
              data-url={getCalendlyUrl()}
              style={{
                minWidth: '320px',
                height: '750px'
              }}
            />
          </motion.div>
        </div>
      </section>
    </div>
  )
}
