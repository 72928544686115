import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export function useScrollToTop() {
    const { pathname } = useLocation();

    useEffect(() => {
        // Using 'auto' instead of 'smooth' prevents animation queuing
        // and provides immediate response on route changes
        const timeoutId = setTimeout(() => {
            window.scrollTo({
                top: 0,
                behavior: "auto",
            });
        }, 0);

        // Cleanup timeout to prevent scroll after component unmount
        return () => clearTimeout(timeoutId);
    }, [pathname]);
}
