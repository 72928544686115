"use client";

import React from "react";
import { Separator } from "./ui/separator";
import { useTranslation } from "../context/TranslationContext";

/**
 * Array of stats to be displayed in the about section
 * Each stat corresponds to a translation key in the format 'about.stats.[key]'
 */
const stats = ["experience", "cases", "clients"] as const;

/**
 * About section component
 * Displays professional background, credentials, and key statistics
 * Uses CSS for animations instead of Framer Motion
 */
export function About() {
    const { t } = useTranslation();

    return (
        <section className="py-10 bg-gray-50">
            <div className="mx-auto max-w-7xl px-6 lg:px-8">
                <div className="grid grid-cols-1 gap-16 lg:grid-cols-2 lg:gap-24">
                    {/* Profile Image - Simplified */}
                    <div className="relative overflow-hidden rounded-2xl bg-gray-100">
                        <img
                            src="/images/alexandra.png?width=600&quality=80&format=webp"
                            alt={String(t("about.image.alt"))}
                            width="600"
                            height="800"
                            fetchPriority="high"
                            decoding="async"
                            className="object-cover w-full h-full"
                        />
                        <div className="absolute inset-0 bg-gradient-to-t from-[#690f39]/20 to-transparent" />
                    </div>

                    {/* Professional Information */}
                    <div className="flex flex-col justify-center">
                        <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                            {t("about.name")}
                        </h2>
                        <div className="mt-4 flex items-center text-[#690f39]">
                            <span className="text-lg font-semibold">
                                {t("about.titles.education")}
                            </span>
                            <Separator
                                orientation="vertical"
                                className="mx-2 h-4"
                            />
                            <span className="text-lg font-semibold">
                                {t("about.titles.university")}
                            </span>
                        </div>

                        <p className="mt-6 text-lg leading-8 text-gray-600">
                            {t("about.description")}
                        </p>

                        <div className="mt-8 space-y-4 text-gray-600">
                            {stats.map((stat) => (
                                <div
                                    key={stat}
                                    className="flex items-center gap-3"
                                >
                                    <div className="h-10 w-10 rounded-full bg-[#fff1f1] flex items-center justify-center">
                                        <span className="text-[#690f39] font-semibold">
                                            {t(`about.stats.${stat}.number`)}
                                        </span>
                                    </div>
                                    <span>
                                        {t(`about.stats.${stat}.label`)}
                                    </span>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
