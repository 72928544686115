import React from 'react'
import { Link } from 'react-router-dom'
import {

  Instagram,
  Linkedin
} from 'lucide-react'
import { useTranslation } from '../context/TranslationContext'

export function Footer() {
  const { t } = useTranslation()

  return (
    <footer className="relative">
      {/* Decorative top border with matching background */}
      <div className="absolute top-0 left-0 right-0">
        <svg
          className="w-full rotate-180"
          viewBox="0 0 1440 120"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M0 120L60 110C120 100 240 80 360 70C480 60 600 60 720 65C840 70 960 80 1080 85C1200 90 1320 90 1380 90L1440 90V120H1380C1320 120 1200 120 1080 120C960 120 840 120 720 120C600 120 480 120 360 120C240 120 120 120 60 120H0V120Z"
            fill="rgb(249, 250, 251)"
          />
        </svg>
      </div>

      {/* Main footer with burgundy gradient */}
      <div className="bg-gradient-to-r from-[#690f39] from-50% via-[#8b1d4c] to-[#a62657] text-gray-100">
        <div className="mx-auto max-w-7xl px-6 pt-16 md:pt-28 xl:pt-32 pb-8 lg:px-8">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-12 text-center">
            {/* Company Info */}
            <div className="col-span-1 md:col-span-1">
              <h3 className="text-xl font-bold text-white mb-4">
                {t('footer.company.title')}
              </h3>
              <p className="text-[#ffd4d4] leading-relaxed text-sm">
                {t('footer.company.description')}
              </p>
            </div>

            {/* Mobile layout for Important Links and Contact */}
            <div className="col-span-1 md:col-span-2 grid grid-cols-2 gap-8 md:grid-cols-2 mt-0 md:mt-0">
              {/* Important Links - Adjusted margin for mobile */}
              <div className="mt-0 md:mt-0">
                <h4 className="text-lg font-semibold text-white mb-3">
                  {t('footer.important.title')}
                </h4>
                <ul className="space-y-px">
                  <li>
                    <Link to="/privacy" className="text-sm text-[#ffd4d4] hover:text-white transition-colors">
                      {t('footer.important.privacy')}
                    </Link>
                  </li>
                  <li>
                    <Link to="/terms" className="text-sm text-[#ffd4d4] hover:text-white transition-colors">
                      {t('footer.important.terms')}
                    </Link>
                  </li>
                  <li>
                    <Link to="/legal-notices" className="text-sm text-[#ffd4d4] hover:text-white transition-colors">
                      {t('footer.important.legalNotices')}
                    </Link>
                  </li>
                </ul>
              </div>

              {/* Contact */}
              <div>
                <h4 className="text-lg font-semibold text-white mb-3">
                  {t('footer.contact.title')}
                </h4>
                <ul className="space-y-2">
                  <div className="mt-4 flex justify-center space-x-4">
                    <a href="https://www.instagram.com/lexconnect.ch" className="text-white/70 hover:text-white transition-colors">
                      <Instagram size={28} />
                    </a>
                    <a href="https://www.linkedin.com/in/alexandra-dallüge-77108168" className="text-white/70 hover:text-white transition-colors">
                      <Linkedin size={28} />
                    </a>
                  </div>
                </ul>
              </div>
            </div>
          </div>

          {/* Copyright */}
          <div className="mt-12 pt-4 border-t border-white/10 text-center">
            <p className="text-xs leading-5 text-[#ffd4d4]">
              &copy; {new Date().getFullYear()} {t('footer.copyright')}
            </p>
          </div>
        </div>
      </div>
    </footer>
  )
}
