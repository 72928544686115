import React from 'react';
import { Hero } from '../components/hero';
import { WhyChooseUs } from '../components/services';
import { About } from '../components/about-section';
import { Contact } from '../components/contact';

export function HomePage() {
  return (
    <main>
      <Hero />
      <WhyChooseUs />
      <About />
      <Contact />
    </main>
  );
}
