import React, { useState } from 'react'
import { Dialog } from './ui/dialog'
import { Button } from './ui/button'
import { Input } from './ui/input'
import { useToast } from './ui/toast'
import { templateService } from '../services/templateService'
import { useTranslation } from '../context/TranslationContext'
import { Link } from 'react-router-dom'

// Props interface for the DownloadModal component
interface DownloadModalProps {
  isOpen: boolean                // Controls modal visibility
  onClose: () => void           // Handler for closing the modal
  templateId: string            // Unique identifier for the template
  templateName: string          // Display name of the template
}

export function DownloadModal({ isOpen, onClose, templateId, templateName }: DownloadModalProps) {
  // State management
  const [email, setEmail] = useState('')                    // User's email input
  const [isLoading, setIsLoading] = useState(false)        // Loading state for form submission
  const [acceptedPrivacy, setAcceptedPrivacy] = useState(false)  // Privacy policy acceptance state

  // Hook initialization
  const { toast } = useToast()
  const { t } = useTranslation()

  // Form submission handler
  const handleDownload = async (e: React.FormEvent) => {
    e.preventDefault()

    // Validate privacy policy acceptance before proceeding
    if (!acceptedPrivacy) {
      toast({
        title: String(t('downloadModal.validation.privacy.title')),
        description: String(t('downloadModal.validation.privacy.description')),
        variant: "destructive",
      })
      return
    }

    setIsLoading(true)

    // Attempt to process the download request
    try {
      await templateService.requestDownload(templateId, email)
      toast({
        title: String(t('downloadModal.toast.success.title')),
        description: String(t('downloadModal.toast.success.description')),
      })
      onClose()
    } catch (error) {
      toast({
        title: String(t('downloadModal.toast.error.title')),
        description: String(t('downloadModal.toast.error.description')),
        variant: "destructive",
      })
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <div className="p-6">
        {/* Modal Header */}
        <h2 className="text-2xl font-semibold mb-4">
           {templateName}
        </h2>
        <p className="text-gray-600 mb-6">
          {t('downloadModal.description')}
        </p>

        {/* Download Form */}
        <form onSubmit={handleDownload} className="space-y-4">
          {/* Email Input Field */}
          <div>
            <label htmlFor="email" className="block text-sm font-medium text-gray-700 mb-1">
              {t('downloadModal.form.email.label')}
            </label>
            <Input
              id="email"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder={String(t('downloadModal.form.email.placeholder'))}
              required
              className="w-full"
            />
          </div>

          {/* Privacy Policy Acceptance Checkbox */}
          <div className="flex items-start gap-2">
            <input
              type="checkbox"
              id="privacy-policy"
              checked={acceptedPrivacy}
              onChange={(e) => setAcceptedPrivacy(e.target.checked)}
              className="mt-1 h-4 w-4 rounded border-gray-300 text-[#690f39] focus:ring-[#690f39]"
            />
            <label htmlFor="privacy-policy" className="text-sm text-gray-600">
              {t('downloadModal.form.privacy.text')}{' '}
              <Link
                to="/privacy"
                target="_blank"
                className="text-[#690f39] hover:text-[#8b1d4c] underline"
              >
                {t('downloadModal.form.privacy.link')}
              </Link>
            </label>
          </div>

          {/* Action Buttons */}
          <div className="flex justify-end gap-4">
            {/* Cancel Button */}
            <Button
              type="button"
              variant="outline"
              onClick={onClose}
            >
              {t('downloadModal.buttons.cancel')}
            </Button>

            {/* Submit Button */}
            <Button
              type="submit"
              disabled={isLoading || !email || !acceptedPrivacy}
              className="bg-[#690f39] hover:bg-[#8b1d4c] text-white"
            >
              {isLoading ? t('downloadModal.buttons.downloading') : t('downloadModal.buttons.download')}
            </Button>
          </div>
        </form>
      </div>
    </Dialog>
  )
}
