import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { motion } from 'framer-motion'
import { CheckCircle } from 'lucide-react'
import { useTranslation } from '../context/TranslationContext'

export function SuccessPage() {
  const [countdown, setCountdown] = useState(20)
  const navigate = useNavigate()
  const { t } = useTranslation()

  useEffect(() => {
    const timer = setInterval(() => {
      setCountdown(prev => {
        if (prev <= 1) {
          clearInterval(timer)
          navigate('/')
          return 0
        }
        return prev - 1
      })
    }, 1000)

    return () => clearInterval(timer)
  }, [navigate])

  return (
    <div className="bg-gray-50 min-h-[60vh] flex items-center">
      <div className="mx-auto max-w-7xl px-6 py-24 lg:px-8">
        <motion.div
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          className="text-center"
        >
          <div className="mx-auto h-12 w-12 flex items-center justify-center rounded-full bg-green-100">
            <CheckCircle className="h-6 w-6 text-green-600" />
          </div>
          <h1 className="mt-6 text-3xl font-bold tracking-tight text-gray-900">
            {t('success.title')}
          </h1>
          <p className="mt-4 text-lg text-gray-600">
            {t('success.description')}
          </p>
          <div className="mt-12 space-y-4">
            <div>
              <a href="/" className="text-[#690f39] hover:text-[#8b1d4c] font-medium">
                {t('success.return')}
              </a>
            </div>
            <p className="text-sm text-gray-500">
              {(t('success.redirect') as string).replace('{seconds}', countdown.toString())}
            </p>
          </div>
        </motion.div>
      </div>
    </div>
  )
}
