import { motion } from 'framer-motion'
import { useTranslation } from '../context/TranslationContext'

type ContentItem = {
  id: string;
  text: string;
  list?: string[];
  link?: {
    text: string;
    url: string;
  };
}

export function TermsPage() {
  const { t } = useTranslation()

  // Function to decode email for display
  const decodeEmail = (encodedEmail: string) => {
    return encodedEmail.replace(/\[at\]/g, '@').replace(/\[dot\]/g, '.');
  }

  const renderText = (text: string, link?: { text: string; url: string }) => {
    if (!link) {
      // Handle emails and bold text
      const parts = text.split(/(\*[^*]+\*|\[email\].*?\[\/email\])/g);
      return (
        <>
          {parts.map((part, index) => {
            if (part.startsWith('*') && part.endsWith('*')) {
              return <span key={index} className="font-semibold">{part.slice(1, -1)}</span>;
            }
            if (part.startsWith('[email]') && part.endsWith('[/email]')) {
              const encodedEmail = part.slice(7, -8); // Remove [email] and [/email] tags
              return (
                <span key={index} className="select-all">
                  {decodeEmail(encodedEmail)}
                </span>
              );
            }
            return part;
          })}
        </>
      );
    }

    const parts = text.split('[here - link]');
    const beforeLink = parts[0];
    const afterLink = parts[1];

    return (
      <>
        {beforeLink.split(/(\*[^*]+\*|\[email\].*?\[\/email\])/g).map((part, index) => {
          if (part.startsWith('*') && part.endsWith('*')) {
            return <span key={`before-${index}`} className="font-semibold">{part.slice(1, -1)}</span>;
          }
          if (part.startsWith('[email]') && part.endsWith('[/email]')) {
            const encodedEmail = part.slice(7, -8);
            return (
              <span key={`before-${index}`} className="select-all">
                {decodeEmail(encodedEmail)}
              </span>
            );
          }
          return part;
        })}
        <a href={link.url} className="text-burgundy hover:text-burgundy/80 font-semibold">
          {link.text}
        </a>
        {afterLink.split(/(\*[^*]+\*|\[email\].*?\[\/email\])/g).map((part, index) => {
          if (part.startsWith('*') && part.endsWith('*')) {
            return <span key={`after-${index}`} className="font-semibold">{part.slice(1, -1)}</span>;
          }
          if (part.startsWith('[email]') && part.endsWith('[/email]')) {
            const encodedEmail = part.slice(7, -8);
            return (
              <span key={`after-${index}`} className="select-all">
                {decodeEmail(encodedEmail)}
              </span>
            );
          }
          return part;
        })}
      </>
    );
  };

  const renderSection = (sectionKey: string) => {
    const content = t(`terms.articles.${sectionKey}.content`, { returnObjects: true }) as unknown as ContentItem[]
    return (
      <div className="text-gray-600 space-y-4 text-justify">
        <h2 className="text-2xl font-semibold text-gray-900 mt-12 mb-4">
          {t(`terms.articles.${sectionKey}.title`)}
        </h2>
        {content.map((item) => (
          <div key={item.id}>
            <p className="text-gray-600">
              <span className="font-medium">{item.id} </span>
              {renderText(item.text, item.link)}
            </p>
            {item.list && (
              <ul className="list-disc ml-8 mt-2 space-y-1">
                {item.list.map((listItem, index) => (
                  <li key={index} className="text-gray-600">{listItem}</li>
                ))}
              </ul>
            )}
          </div>
        ))}
      </div>
    )
  }

  return (
    <div className="bg-gray-50">
      {/* Hero Section */}
      <section className="pt-20 pb-12">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
            className="text-center"
          >
            <h1 className="text-4xl font-bold text-gray-900 tracking-tight sm:text-5xl">
              {t('terms.hero.title')}
            </h1>
          </motion.div>
        </div>
      </section>

      {/* Main Content */}
      <section className="pb-24">
        <div className="mx-auto max-w-3xl px-6 lg:px-8">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.5 }}
            className="prose prose-lg max-w-none"
          >
            {/* Preamble */}
            <div className="text-gray-600 space-y-4 text-justify">
              <h2 className="text-2xl font-semibold text-gray-900 mt-12 mb-4">
                {t('terms.articles.preamble.title')}
              </h2>
              {(t('terms.articles.preamble.content', { returnObjects: true }) as string[]).map((paragraph: string, index: number) => (
                <p key={index} className="text-gray-600 text-justify">{renderText(paragraph)}</p>
              ))}
            </div>

            {/* Numbered Sections */}
            {renderSection('1')}
            {renderSection('2')}
            {renderSection('3')}
            {renderSection('4')}
            {renderSection('5')}
            {renderSection('6')}
            {renderSection('7')}
            {renderSection('8')}
            {renderSection('9')}
            {renderSection('10')}
            {renderSection('11')}
            {renderSection('12')}
            {renderSection('13')}

            {/* Contact Information and Last Updated */}
            <div className="text-gray-600 mt-12 pt-12 border-t border-gray-200">
              <p className="mt-8 text-sm text-gray-500">
                {t('terms.hero.lastUpdated')}
              </p>
            </div>
          </motion.div>
        </div>
      </section>
    </div>
  )
}
