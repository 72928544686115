export const templateService = {
  async requestDownload(templateId: string, email: string): Promise<void> {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}templates/request-download`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          templateId,
          email
        }),
      })

      if (!response.ok) {
        throw new Error('Download failed')
      }

      const blob = await response.blob()
      const url = window.URL.createObjectURL(blob)
      const a = document.createElement('a')
      a.href = url
      // Get filename from Content-Disposition header
      const disposition = response.headers.get('content-disposition')
      const filename = disposition?.split('filename=')[1]?.replace(/"/g, '') || templateId
      a.download = filename  // This forces download instead of opening
      document.body.appendChild(a)
      a.click()
      window.URL.revokeObjectURL(url)
      document.body.removeChild(a)
    } catch (error) {
      console.error('Download error:', error)
      throw error
    }
  }
}