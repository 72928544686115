'use client'

import React from 'react'
import { motion } from 'framer-motion'
import { Separator } from './ui/separator'
import { useTranslation } from '../context/TranslationContext'

/**
 * Array of stats to be displayed in the about section
 * Each stat corresponds to a translation key in the format 'about.stats.[key]'
 */
const stats = ['experience', 'cases', 'clients'] as const

/**
 * About section component
 * Displays professional background, credentials, and key statistics
 * Includes animated elements and responsive layout
 */
export function About() {
  const { t } = useTranslation()

  return (
    <section className="py-10 bg-gray-50">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <motion.div
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{ duration: 0.8 }}
          className="grid grid-cols-1 gap-16 lg:grid-cols-2 lg:gap-24"
        >
          {/* Profile Image with Gradient Overlay */}
          <div className="relative overflow-hidden rounded-2xl">
            <img
              src="/images/alexandra.png"
              alt={String(t('about.image.alt'))}
              className="object-cover w-full h-full"
            />
            <div className="absolute inset-0 bg-gradient-to-t from-[#690f39]/20 to-transparent" />
          </div>

          {/* Professional Information */}
          <div className="flex flex-col justify-center">
            {/* Name and Credentials */}
            <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
              {t('about.name')}
            </h2>
            <div className="mt-4 flex items-center text-[#690f39]">
              <span className="text-lg font-semibold">{t('about.titles.education')}</span>
              <Separator orientation="vertical" className="mx-2 h-4" />
              <span className="text-lg font-semibold">{t('about.titles.university')}</span>
            </div>

            {/* Professional Description */}
            <p className="mt-6 text-lg leading-8 text-gray-600">
              {t('about.description')}
            </p>

            {/* Key Statistics */}
            <div className="mt-8 space-y-4 text-gray-600">
              {stats.map((stat) => (
                <div key={stat} className="flex items-center gap-3">
                  {/* Circular Stat Display */}
                  <div className="h-10 w-10 rounded-full bg-[#fff1f1] flex items-center justify-center">
                    <span className="text-[#690f39] font-semibold">
                      {t(`about.stats.${stat}.number`)}
                    </span>
                  </div>
                  <span>{t(`about.stats.${stat}.label`)}</span>
                </div>
              ))}
            </div>
          </div>
        </motion.div>
      </div>
    </section>
  )
}
