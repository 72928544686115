import React from 'react';
import { Helmet } from 'react-helmet';

interface SeoProps {
  title: string;
  description: string;
  path: string;
  schema?: object;
  noindex?: boolean;
}

export function Seo({ 
  title, 
  description, 
  path, 
  schema,
  noindex = false 
}: SeoProps) {
  const siteUrl = 'https://www.lexconnect.ch';
  const fullUrl = `${siteUrl}${path}`;

  return (
    <Helmet>
      {/* Basic meta tags */}
      <title>{title}</title>
      <meta name="description" content={description} />
      <link rel="canonical" href={fullUrl} />
      <meta name="language" content="en" />
      
      {/* Robots meta tag */}
      {noindex && <meta name="robots" content="noindex,nofollow" />}

      {/* JSON-LD structured data */}
      {schema && (
        <script type="application/ld+json">
          {JSON.stringify(schema)}
        </script>
      )}
    </Helmet>
  );
}