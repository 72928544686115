import React, { createContext, useContext, useEffect, useState } from 'react'
import enTranslations from '../translations/en.json'
import frTranslations from '../translations/fr.json'

// Define supported languages
type Language = 'en' | 'fr'

/**
 * Translation context interface
 * Defines the shape of the context value
 * - language: Current active language
 * - setLanguage: Function to change language
 * - t: Translation function that returns string or array based on key
 */
interface TranslationContextType {
  language: Language
  setLanguage: (lang: Language) => void
  t: (key: string, options?: {
    returnObjects?: boolean;
    current?: number;
    max?: number;
    interpolation?: { escapeValue: boolean };
  }) => string | string[]
}

// Load translation files
const translations = {
  en: enTranslations,
  fr: frTranslations,
}

// Create context with undefined default value
const TranslationContext = createContext<TranslationContextType | undefined>(undefined)

/**
 * Translation Provider Component
 * Manages language state and provides translation functions
 * Features:
 * - Automatic language detection
 * - Local storage persistence
 * - Nested key support
 * - HTML lang attribute updates
 */
export function TranslationProvider({ children }: { children: React.ReactNode }) {
  /**
   * Determines initial language based on:
   * 1. Stored preference in localStorage
   * 2. Browser language
   * 3. Defaults to English
   */
  const getInitialLanguage = (): Language => {
    const stored = localStorage.getItem('language') as Language
    if (stored && ['en', 'fr'].includes(stored)) {
      return stored
    }

    const browserLang = navigator.language.split('-')[0]
    return browserLang === 'fr' ? 'fr' : 'en'
  }

  const [language, setLanguage] = useState<Language>(getInitialLanguage)

  // Persist language choice and update HTML lang attribute
  useEffect(() => {
    localStorage.setItem('language', language)
    document.documentElement.lang = language
  }, [language])

  /**
   * Translation function
   * Retrieves nested translations using dot notation
   * @param key - Translation key (e.g., 'navigation.home')
   * @param options - Optional settings for array returns
   * @returns Translated string or array of strings
   */
  const t = (key: string, options?: {
    returnObjects?: boolean;
    current?: number;
    max?: number;
    interpolation?: { escapeValue: boolean };
  }): string | string[] => {
    const keys = key.split('.')
    let value: any = translations[language]

    // Traverse nested objects to find translation
    for (const k of keys) {
      if (value?.[k] === undefined) {
        console.warn(`Translation missing for key: ${key}`)
        return options?.returnObjects ? [] : key
      }
      value = value[k]
    }

    // Handle array returns for menu items, lists, etc.
    if (options?.returnObjects) {
      return Array.isArray(value) ? value : []
    }

    return String(value)
  }

  return (
    <TranslationContext.Provider value={{ language, setLanguage, t }}>
      {children}
    </TranslationContext.Provider>
  )
}

/**
 * Custom hook to use translations
 * Provides type-safe access to translation context
 * @throws Error if used outside TranslationProvider
 */
export function useTranslation() {
  const context = useContext(TranslationContext)
  if (context === undefined) {
    throw new Error('useTranslation must be used within a TranslationProvider')
  }
  return context
}
