"use client";

import React, { useState } from "react";
import { motion } from "framer-motion";
import { Target, Wrench, Handshake } from "lucide-react";
import { useTranslation } from "../context/TranslationContext";

/**
 * Service features configuration
 * Each feature includes:
 * - key: Unique identifier for translation mapping
 * - icon: Lucide icon component
 * - title: Display title
 * - description: Feature description
 */
const reasons = [
    {
        key: "focus",
        icon: <Target className="h-6 w-6" />,
        title: "Swiss Quality & Reliability",
        description:
            "Benefit from our deep understanding of Swiss legal standards and commitment to excellence in every service we provide.",
    },
    {
        key: "tailored",
        icon: <Wrench className="h-6 w-6" />,
        title: "Personal Approach",
        description:
            "Experience dedicated support with a personal touch. We take time to understand your unique needs and challenges.",
    },
    {
        key: "trusted",
        icon: <Handshake className="h-6 w-6" />,
        title: "International Expertise",
        description:
            "Access comprehensive legal solutions that bridge Swiss and international business requirements.",
    },
];

/**
 * WhyChooseUs component
 * Displays key service features with hover animations and gradient effects
 * Features:
 * - Interactive hover states
 * - Responsive grid layout
 * - Animated entrance effects
 * - Localized content
 */
export function WhyChooseUs() {
    // Track which feature is being hovered for animation
    const [hoveredFactor, setHoveredFactor] = useState<number | null>(null);
    const { t } = useTranslation();

    const handleMouseEnter = (index: number) => {
        setHoveredFactor(index);
    };

    const handleMouseLeave = () => {
        setHoveredFactor(null);
    };

    return (
        <section className="pt-24 pb-12 bg-gradient-to-b from-white to-gray-50">
            <div className="mx-auto max-w-7xl px-6 lg:px-8">
                {/* Section Title with Animation */}
                <motion.div
                    initial={{ opacity: 0, y: 20 }}
                    whileInView={{ opacity: 1, y: 0 }}
                    transition={{ duration: 0.8 }}
                    className="text-center mb-16"
                >
                    <h2 className="text-4xl font-bold tracking-tight text-gray-900">
                        {t("services.title")}
                    </h2>
                </motion.div>

                {/* Features Grid */}
                <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
                    {reasons.map((reason, index) => (
                        <motion.div
                            key={index}
                            initial={{ opacity: 0, y: 20 }}
                            whileInView={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.5, delay: index * 0.1 }}
                            onMouseEnter={() => handleMouseEnter(index)}
                            onMouseLeave={handleMouseLeave}
                            className="relative group"
                        >
                            {/* Feature Card with Hover Effects */}
                            <div
                                className={`h-full rounded-2xl p-8 transition-all duration-500 ${
                                    hoveredFactor === index
                                        ? "bg-gradient-to-r from-[#690f39] to-[#8b1d4c] transform -translate-y-1"
                                        : "bg-white hover:shadow-xl"
                                }`}
                            >
                                {/* Feature Icon */}
                                <div
                                    className={`p-3 rounded-xl inline-block mb-4 transition-colors duration-500 ${
                                        hoveredFactor === index
                                            ? "bg-white/10"
                                            : "bg-[#fff1f1]"
                                    }`}
                                >
                                    <div
                                        className={`transition-colors duration-500 ${
                                            hoveredFactor === index
                                                ? "text-white"
                                                : "text-[#690f39]"
                                        }`}
                                    >
                                        {reason.icon}
                                    </div>
                                </div>

                                {/* Feature Title and Description */}
                                <h3
                                    className={`text-xl font-semibold mb-3 transition-colors duration-500 ${
                                        hoveredFactor === index
                                            ? "text-white"
                                            : "text-gray-900"
                                    }`}
                                >
                                    {t(`services.reasons.${reason.key}.title`)}
                                </h3>
                                <p
                                    className={`transition-colors duration-500 ${
                                        hoveredFactor === index
                                            ? "text-white/90"
                                            : "text-gray-600"
                                    }`}
                                >
                                    {t(
                                        `services.reasons.${reason.key}.description`
                                    )}
                                </p>
                            </div>
                        </motion.div>
                    ))}
                </div>
            </div>
        </section>
    );
}
