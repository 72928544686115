import React from 'react'
import { motion } from 'framer-motion'
import { Button } from '../components/ui/button'
import { Card } from '../components/ui/card'
import { Checkbox } from '../components/ui/checkbox'
import {
  FileText,
  Check,
  Clock
} from 'lucide-react'
import { useToast } from '../components/ui/toast'
import { stripeService } from '../services/stripeService'
import { useTranslation } from '../context/TranslationContext'
import { Link } from 'react-router-dom'

/**
 * Template interface defining the structure of each template package
 * Includes pricing, features, and display options
 */
interface Template {
  id: string;
  title: string;
  price: number;  // Price in standard format (e.g., 110 for CHF 110.00)
  description: string;
  features: string[];
  popular?: boolean;
  comingSoon?: boolean;
}

/**
 * Helper function to convert markdown-style bold to JSX
 */
const formatBoldText = (text: string) => {
  const parts = text.split(/\*(.*?)\*/)
  return parts.map((part, index) =>
    index % 2 === 0 ? part : <strong key={index}>{part}</strong>
  )
}

/**
 * Templates Page Component
 * Displays available legal document templates with pricing and features
 * Handles template purchases through Stripe integration
 */
export function TemplatesPage() {
  const { toast } = useToast()
  const { t, language } = useTranslation()
  const [acceptedTerms, setAcceptedTerms] = React.useState<{ [key: string]: boolean }>({})

  // Get template data from translations with proper typing
  const packages = t('templates.packages', { returnObjects: true }) as unknown as Template[]

  /**
   * Handles template purchase attempts
   * - Blocks purchase of upcoming templates
   * - Initiates Stripe checkout for available templates
   * - Shows appropriate feedback messages
   *
   * @param template - The template being purchased
   */
  const handlePurchase = async (template: Template) => {
    if (template.comingSoon) {
      toast({
        title: t('templates.toast.comingSoon.title') as string,
        description: t('templates.toast.comingSoon.description') as string,
      })
      return
    }

    try {
      // Pass current language preference and price to maintain localization in checkout
      const url = await stripeService.createCheckoutSession(
        template.id,
        language,
        template.price
      )
      window.location.href = url
    } catch (error) {
      toast({
        title: t('templates.toast.error.title') as string,
        description: t('templates.toast.error.description') as string,
        variant: "destructive",
      })
    }
  }

  return (
    <div className="bg-gray-50">
      {/* Hero Section */}
      <section className="py-20">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
            className="text-center"
          >
            <h1 className="text-4xl font-bold text-gray-900 tracking-tight sm:text-5xl">
              {t('templates.hero.title')}
            </h1>
            <p className="mt-6 text-lg leading-8 text-gray-600 max-w-2xl mx-auto">
              {t('templates.hero.description')}
            </p>
            {language === 'en' && (
              <p className="mt-4 text-lg leading-8 text-gray-600 max-w-2xl mx-auto">
                {t('templates.hero.description2')}
              </p>
            )}
            <p className="mt-6 text-lg leading-8 text-gray-600 max-w-2xl mx-auto">
              {t('templates.info.custom')} {' '}
              <Link to="/contact" className="text-[#690f39] hover:text-[#8b1d4c] font-medium">
                {t('templates.info.contactLink')}
              </Link>
            </p>
          </motion.div>
        </div>
      </section>

      {/* Templates Grid Section */}
      <section className="pt-2 pb-10 bg-gray-50">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="grid grid-cols-1 gap-8 md:grid-cols-2 lg:grid-cols-3">
            {packages.map((pkg, index) => (
              <motion.div
                key={pkg.id}
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ duration: 0.5, delay: index * 0.1 }}
                className={`${pkg.popular ? 'order-first md:order-none' : ''}`}
              >
                {/* Template Card */}
                <Card className={`p-8 h-full flex flex-col relative
                  ${pkg.popular ? 'ring-2 ring-[#690f39]' : ''}
                  ${pkg.comingSoon ? 'opacity-75' : ''}`}
                >
                  {/* Popular Badge */}
                  {pkg.popular && (
                    <div className="absolute -top-3 left-1/2 transform -translate-x-1/2">
                      <span className="inline-flex items-center px-3 py-1 rounded-full text-sm font-medium bg-[#690f39] text-white">
                        {t('templates.labels.popular')}
                      </span>
                    </div>
                  )}

                  {/* Coming Soon Badge */}
                  {pkg.comingSoon && (
                    <div className="absolute -top-3 left-1/2 transform -translate-x-1/2">
                      <span className="inline-flex items-center gap-1 px-3 py-1 rounded-full text-sm font-medium bg-gray-600 text-white">
                        <Clock className="h-3 w-3" />
                        {t('templates.labels.comingSoon')}
                      </span>
                    </div>
                  )}

                  {/* Template Header */}
                  <div className="flex items-center gap-4 mb-6">
                    <div className="p-2 rounded-lg bg-[#fff1f1]">
                      <FileText className="h-6 w-6 text-[#690f39]" />
                    </div>
                    <h3 className="text-xl font-semibold text-gray-900">{pkg.title}</h3>
                  </div>

                  {/* Pricing Information */}
                  <div className="mb-6">
                    <p className="text-4xl font-bold text-gray-900">
                      CHF {pkg.price}
                    </p>
                    <p className="mt-2 text-gray-600">
                      {formatBoldText(pkg.description)}
                    </p>
                  </div>

                  {/* Feature List */}
                  <div className="flex-grow space-y-3 mb-8">
                    {Array.isArray(pkg.features) && pkg.features.map((feature: string) => (
                      <div key={feature} className="flex items-center gap-3">
                        <Check className="h-5 w-5 text-[#690f39] flex-shrink-0" />
                        <span className="text-gray-600">{feature}</span>
                      </div>
                    ))}
                  </div>

                  {/* Terms Checkbox */}
                  {!pkg.comingSoon && (
                    <div className="mb-4 mt-4">
                      <label className="flex items-start space-x-3">
                        <Checkbox
                          id={`terms-${pkg.id}`}
                          checked={acceptedTerms[pkg.id] || false}
                          onChange={(e) => setAcceptedTerms(prev => ({
                            ...prev,
                            [pkg.id]: e.target.checked
                          }))}
                        />
                        <span className="text-xs text-gray-600">
                          {t('templates.consent.prefix')}{' '}
                          <Link to="/terms" className="text-[#690f39] hover:text-[#8b1d4c] underline">
                            {t('templates.consent.termsLink')}
                          </Link>{' '}
                          {t('templates.consent.middle')}{' '}
                          <Link to="/privacy" className="text-[#690f39] hover:text-[#8b1d4c] underline">
                            {t('templates.consent.privacyLink')}
                          </Link>
                        </span>
                      </label>
                    </div>
                  )}

                  {/* Purchase Button */}
                  <Button
                    onClick={() => handlePurchase(pkg)}
                    disabled={pkg.comingSoon || !acceptedTerms[pkg.id]}
                    className={`w-full ${
                      pkg.comingSoon
                        ? 'bg-gray-300 hover:bg-gray-300 cursor-not-allowed'
                        : !acceptedTerms[pkg.id]
                          ? pkg.popular
                            ? 'bg-[#8b1d4c]/40 hover:bg-[#8b1d4c]/40 text-white cursor-not-allowed'
                            : 'bg-[#fff1f1] hover:bg-[#fff1f1] text-[#690f39]/40 cursor-not-allowed'
                          : pkg.popular
                            ? 'bg-[#690f39] hover:bg-[#8b1d4c] text-white'
                            : 'bg-[#fff1f1] hover:bg-[#ffe4e4] text-[#690f39]'
                    }`}
                  >
                    {pkg.comingSoon
                      ? t('templates.buttons.comingSoon')
                      : t('templates.buttons.purchase')
                    }
                  </Button>
                </Card>
              </motion.div>
            ))}
          </div>

          {/* Additional Information */}
          <div className="mt-16 text-center">
            <p className="text-gray-600">
              {t('templates.info.format')}
            </p>
          </div>
        </div>
      </section>
    </div>
  )
}
